export const data = [
  {
    id: "1",
    opus: "1",
    number: "1",
    title: "Brownies Ponies",
    date: "October 1930",
    scoring: "Piano",
  },
  {
    id: "2",
    opus: "1",
    number: "2",
    title: "Waltz Petite",
    date: "February 1931",
    scoring: "Piano",
  },
  {
    id: "3",
    opus: "1",
    number: "3",
    title: "War of the Goblins",
    date: "July 1932",
    scoring: "Piano",
  },
  {
    id: "4",
    opus: "1",
    number: "4",
    title: "Waltz",
    date: "March 1933",
    scoring: "Piano",
  },
  {
    id: "5",
    opus: "1",
    number: "5",
    title: "The Brook",
    date: "May 1933",
    scoring: "Piano",
  },
  {
    id: "6",
    opus: "1",
    number: "6",
    title: "Evening Song",
    date: "July 1933",
    scoring: "Piano",
  },
  {
    id: "7",
    opus: "1",
    number: "7",
    title: "Variations",
    date: "November 1934",
    scoring: "Piano",
  },
  {
    id: "8",
    opus: "1",
    number: "8",
    title: "March",
    date: "January 1935",
    scoring: "Piano",
  },
  {
    id: "9",
    opus: "1",
    number: "9",
    title: "Fantasie",
    date: "March 1935",
    scoring: "Piano",
  },
  {
    id: "10",
    opus: "1",
    number: "10",
    title: "Solitary Interlude (Prelude)",
    date: "August 1935",
    scoring: "Piano",
  },
  {
    id: "11",
    opus: "2",
    number: "1",
    title: "Nocturne",
    date: "October 4-25, 1936",
    scoring: "Piano",
  },
  {
    id: "12",
    opus: "2",
    number: "2",
    title: "The Storm at Sunset",
    date: "October 25 - November 8, 1936",
    scoring: "Piano",
  },
  {
    id: "13",
    opus: "2",
    number: "3",
    title: "Fantasia in Bright Ochre",
    date: "November 22 - December 6, 1936",
    scoring: "Piano",
  },
  {
    id: "14",
    opus: "2",
    number: "4",
    title: "Morning Pastorale",
    date: "December 28, 1936 - Jan 16, 1937",
    scoring: "Piano",
  },
  {
    id: "15",
    opus: "2",
    number: "5",
    title: "Midnight Tango",
    date: "January 30, 1937 - February 21, 1937",
    scoring: "Piano (2)",
  },
  {
    id: "16",
    opus: "2",
    number: "6",
    title: "The Peasant Boy",
    date: "January 10, 1937",
    scoring: "Piano",
  },
  {
    id: "17",
    opus: "E",
    number: "1",
    title: "On the Trail (Excerpts)",
    date: "January 1937",
    scoring: "Strings",
  },
  {
    id: "18",
    opus: "E",
    number: "2",
    title: "The Peasant Boy",
    date: "January 1937",
    scoring: "Orchestra",
  },
  {
    id: "19",
    opus: "E",
    number: "3",
    title: "The Storm at Sunset",
    date: "February 1937",
    scoring: "Orchestra",
  },
  {
    id: "20",
    opus: "2",
    number: "7",
    title: "The Lord’s Prayer",
    date: "March 24, 1937",
    scoring: "Chorus (A Capella)",
  },
  {
    id: "21",
    opus: "2",
    number: "8",
    title: "Prelude",
    date: "April 15, 1937",
    scoring: "Violin and Piano",
  },
  {
    id: "22",
    opus: "2",
    number: "9",
    title: "Reverie",
    date: "May 1937",
    scoring: "Piano",
  },
  {
    id: "23",
    opus: "2",
    number: "10",
    title: "Bells of Evening",
    date: "June 1937",
    scoring: "French Horn and Piano",
  },
  {
    id: "24",
    opus: "2",
    number: "11",
    title: "Melody in D-Flat",
    date: "June 1937",
    scoring: "Viola and Piano",
  },
  {
    id: "25",
    opus: "X",
    number: "1",
    title: "Connie",
    date: "June 1937",
    scoring: "Popular Song",
  },
  {
    id: "26",
    opus: "X",
    number: "2",
    title: "You’ll Never Know",
    date: "July 1937",
    scoring: "Popular Song",
  },
  {
    id: "27",
    opus: "D",
    number: "1",
    title: "You’ll Never Know",
    date: "September 1937",
    scoring: "Orchestra",
  },
  {
    id: "28",
    opus: "D",
    number: "2",
    title: "Satan Takes a Holiday",
    date: "September 1937",
    scoring: "Dance Band",
  },
  {
    id: "29",
    opus: "E",
    number: "4",
    title: "Solitude",
    date: "September 1937",
    scoring: "Trumpet Trio and Piano",
  },
  {
    id: "30",
    opus: "X",
    number: "3",
    title: "I’m Afraid Its Contagious",
    date: "October 1937",
    scoring: "Popular Song",
  },
  {
    id: "31",
    opus: "D",
    number: "3",
    title: "Caravan",
    date: "October 1937",
    scoring: "Dance Band",
  },
  {
    id: "32",
    opus: "2",
    number: "12",
    title: "Rhapsody",
    date: "November 1937 - Finished May 1938",
    scoring: "Piano",
  },
  {
    id: "33",
    opus: "X",
    number: "4",
    title: "Dancing With My Shadow",
    date: "November 1937",
    scoring: "Popular Song",
  },
  {
    id: "34",
    opus: "D",
    number: "4",
    title: "Goodnight Sweetheart #1",
    date: "November 1937",
    scoring: "Dance Band",
  },
  {
    id: "35",
    opus: "D",
    number: "5",
    title: "Connie",
    date: "December 1937",
    scoring: "Dance Band",
  },
  {
    id: "36",
    opus: "3",
    number: "1",
    title: "Brass Octet",
    date: "January - February 1938",
    scoring: "Brass (8 Instruments)",
  },
  {
    id: "37",
    opus: "3",
    number: "2",
    title: "Andante",
    date: "December 1937 - February 1938",
    scoring: "Strings",
  },
  {
    id: "38",
    opus: "X",
    number: "5",
    title: "It All Depends on You",
    date: "March 1938",
    scoring: "Popular Song",
  },
  {
    id: "39",
    opus: "B",
    number: "1",
    title: "The Voice",
    date: "March 1938",
    scoring: "Song",
  },
  {
    id: "40",
    opus: "D",
    number: "6",
    title: "Fiddler’s Blues",
    date: "March 1938",
    scoring: "Dance Band",
  },
  {
    id: "41",
    opus: "G",
    number: "1",
    title: "High Ho",
    date: "March 1938",
    scoring: "Male Chorus",
  },
  {
    id: "42",
    opus: "X",
    number: "6",
    title: "Burn’d Letters",
    date: "April 1938",
    scoring: "Popular Song",
  },
  {
    id: "43",
    opus: "X",
    number: "7",
    title: "Punchin’ Gabriel’s Cattle",
    date: "April 1938",
    scoring: "Popular Song",
  },
  {
    id: "44",
    opus: "X",
    number: "8",
    title: "Garden in Old Santa Fe",
    date: "April 1938",
    scoring: "Popular Song",
  },
  {
    id: "45",
    opus: "X",
    number: "9",
    title: "Lost in a Dream of Love",
    date: "April 1938",
    scoring: "Popular Song",
  },
  {
    id: "46",
    opus: "A",
    number: "1",
    title: "Star Dust",
    date: "April 1938",
    scoring: "Trumpet and Piano",
  },
  {
    id: "47",
    opus: "B",
    number: "2",
    title: "I Am That I Am",
    date: "April 1938",
    scoring: "Song",
  },
  {
    id: "48",
    opus: "B",
    number: "3",
    title: "Contemplation",
    date: "April 1938",
    scoring: "Song",
  },
  {
    id: "49",
    opus: "B",
    number: "4",
    title: "The Way of Life",
    date: "May 1938",
    scoring: "Song",
  },
  {
    id: "50",
    opus: "B",
    number: "5",
    title: "Peace",
    date: "May 1938",
    scoring: "Song",
  },
  {
    id: "51",
    opus: "D",
    number: "7",
    title: "Star Dust",
    date: "May 1938",
    scoring: "Dance Band",
  },
  {
    id: "52",
    opus: "D",
    number: "8",
    title: "Rhapsody in Blue (Excerpts)",
    date: "May 1938",
    scoring: "Dance Band",
  },
  {
    id: "53",
    opus: "D",
    number: "9",
    title: "On the Trail (Grand Canyon Excerpts)",
    date: "May 1938",
    scoring: "Dance Band",
  },
  {
    id: "54",
    opus: "D",
    number: "10",
    title: "Stompin’ at the Savoy",
    date: "May 1938",
    scoring: "Dance Band",
  },
  {
    id: "55",
    opus: "D",
    number: "11",
    title: "Whistle While You Work",
    date: "May 1938",
    scoring: "Dance Band",
  },
  {
    id: "56",
    opus: "D",
    number: "12",
    title: "Heigh Ho",
    date: "May 1938",
    scoring: "Dance Band",
  },
  {
    id: "57",
    opus: "D",
    number: "13",
    title: "One Song",
    date: "May 1938",
    scoring: "Dance Band",
  },
  {
    id: "58",
    opus: "B",
    number: "6",
    title: "My America",
    date: "June 1938",
    scoring: "Song",
  },
  {
    id: "59",
    opus: "D",
    number: "14",
    title: "True Confession",
    date: "June 1938",
    scoring: "Dance Band",
  },
  {
    id: "60",
    opus: "D",
    number: "15",
    title: "Goodnight Sweetheart #2",
    date: "June 1938",
    scoring: "Dance Band",
  },
  {
    id: "61",
    opus: "B",
    number: "7",
    title: "The Flag of Liberty",
    date: "July 1938",
    scoring: "Song",
  },
  {
    id: "62",
    opus: "B",
    number: "8",
    title: "Jewell of My Heart",
    date: "July 1938",
    scoring: "Song",
  },
  {
    id: "63",
    opus: "B",
    number: "9",
    title: "Ecstasy",
    date: "July 1938",
    scoring: "Song",
  },
  {
    id: "64",
    opus: "C",
    number: "1",
    title: "Basso Ostinato",
    date: "July 1938",
    scoring: "Chorus",
  },
  {
    id: "65",
    opus: "B",
    number: "10",
    title: "My Son",
    date: "August 1938",
    scoring: "Song",
  },
  {
    id: "66",
    opus: "B",
    number: "11",
    title: "Forgive Me",
    date: "August 1938",
    scoring: "Song",
  },
  {
    id: "67",
    opus: "B",
    number: "12",
    title: "An Old Brocade",
    date: "August 1938",
    scoring: "Song",
  },
  {
    id: "68",
    opus: "5",
    number: "1",
    title: "Prayer",
    date: "September 1938",
    scoring: "Song",
  },
  {
    id: "69",
    opus: "5",
    number: "2",
    title: "Remembrance (Trio for Two Flutes and Piano)",
    date: "September - December 1938",
    scoring: "Flutes (2) and Piano",
  },
  {
    id: "70",
    opus: "D",
    number: "16",
    title: "You Go to My Head",
    date: "September 1938",
    scoring: "Dance Band",
  },
  {
    id: "71",
    opus: "4",
    number: "1",
    title: "Largo Molto",
    date: "October 20, 1938",
    scoring: "Piano",
  },
  {
    id: "72",
    opus: "4",
    number: "2",
    title: "Andante",
    date: "November 2, 1938",
    scoring: "Piano",
  },
  {
    id: "73",
    opus: "B",
    number: "13",
    title: "Sally Linden’s Tune",
    date: "November 1938",
    scoring: "Song",
  },
  {
    id: "74",
    opus: "5",
    number: "3",
    title: "Hymn",
    date: "November 30, 1938",
    scoring: "Piano",
  },
  {
    id: "75",
    opus: "4",
    number: "3",
    title: "Adagio",
    date: "December 16, 1938",
    scoring: "Piano",
  },
  {
    id: "76",
    opus: "X",
    number: "10",
    title: "Four Melted Trumpets",
    date: "December 1938",
    scoring: "Popular Song",
  },
  {
    id: "77",
    opus: "X",
    number: "11",
    title: "Heartache",
    date: "December 1938",
    scoring: "Popular Song",
  },
  {
    id: "78",
    opus: "D",
    number: "17",
    title: "Star Dust (No. 2)",
    date: "December 1938",
    scoring: "Dance Band",
  },
  {
    id: "79",
    opus: "E",
    number: "5",
    title: "Four Melted Trumpets",
    date: "December 1938",
    scoring: "Trumpets (4) and Piano",
  },
  {
    id: "80",
    opus: "4",
    number: "4",
    title: "Humpty Dumpty",
    date: "January 1939",
    scoring: "Song",
  },
  {
    id: "81",
    opus: "4",
    number: "5",
    title: "Valse Concerte",
    date: "December 1938 - January 1939",
    scoring: "Piano",
  },
  {
    id: "82",
    opus: "5",
    number: "5",
    title: "Today We Live",
    date: "December 1938 - January 1939",
    scoring: "Chorus (A Capella)",
  },
  {
    id: "83",
    opus: "E",
    number: "6",
    title: "Solitude",
    date: "January 1939",
    scoring: "Concert Band",
  },
  {
    id: "84",
    opus: "4",
    number: "6",
    title: "Valse Lente",
    date: "February 19, 1939",
    scoring: "Cello and Piano",
  },
  {
    id: "85",
    opus: "4",
    number: "7",
    title: "Largo",
    date: "February 24, 1939",
    scoring: "Piano",
  },
  {
    id: "86",
    opus: "6",
    number: "",
    title:
      "Camelot (The City of Arthur) (Fouth work for Orchestra $25.00 Prize)",
    date: "February 1939",
    scoring: "Orchestra",
  },
  {
    id: "87",
    opus: "4",
    number: "8",
    title: "March",
    date: "March 1939",
    scoring: "Piano",
  },
  {
    id: "88",
    opus: "D",
    number: "18",
    title: "Omega Phi Girl",
    date: "March 1939",
    scoring: "Dance Band",
  },
  {
    id: "89",
    opus: "4",
    number: "9",
    title: "Bells of an Ancient City",
    date: "April 1939",
    scoring: "3 Pianos",
  },
  {
    id: "90",
    opus: "5",
    number: "4",
    title: "Postlude",
    date: "May 29, 1939",
    scoring: "Piano",
  },
  {
    id: "91",
    opus: "X",
    number: "12",
    title: "I Wrote A Song",
    date: "September 1939",
    scoring: "Popular Song",
  },
  {
    id: "92",
    opus: "D",
    number: "19",
    title: "Heartache",
    date: "September 1939",
    scoring: "Dance Band",
  },
  {
    id: "93",
    opus: "D",
    number: "20",
    title: "Sweet Sue",
    date: "October 1939",
    scoring: "Dance Band",
  },
  {
    id: "94",
    opus: "D",
    number: "21",
    title: "Hawaiian Medley",
    date: "October 1939",
    scoring: "Dance Band",
  },
  {
    id: "95",
    opus: "E",
    number: "7",
    title: "The Lamp Is Low",
    date: "October 1939",
    scoring: "Strings",
  },
  {
    id: "96",
    opus: "5",
    number: "6",
    title: "Though Thou Art Gone",
    date: "January 1940",
    scoring: "Song",
  },
  {
    id: "97",
    opus: "5",
    number: "7",
    title: "Caprice",
    date: "February 1940",
    scoring: "Piano",
  },
  {
    id: "98",
    opus: "8",
    number: "",
    title: "An Etching in Brass",
    date: "March 1940",
    scoring: "Brass Choir",
  },
  {
    id: "99",
    opus: "D",
    number: "22",
    title: "Theme Song (Duke Campagna)",
    date: "April 1940",
    scoring: "Dance Band",
  },
  {
    id: "100",
    opus: "5",
    number: "8",
    title: "Basso Ostinato",
    date: "May 1940",
    scoring: "Piano",
  },
  {
    id: "101",
    opus: "X",
    number: "13",
    title: "That Certain Smile",
    date: "May 1940",
    scoring: "Popular Song",
  },
  {
    id: "102",
    opus: "D",
    number: "23",
    title: "You’ll Never Know (No. 2)",
    date: "May 1940",
    scoring: "Dance Band",
  },
  {
    id: "103",
    opus: "D",
    number: "24",
    title: "Dancing With My Shadow",
    date: "May 1940",
    scoring: "Dance Band",
  },
  {
    id: "104",
    opus: "D",
    number: "25",
    title: "Burn’d Letters",
    date: "May 1940",
    scoring: "Dance Band",
  },
  {
    id: "105",
    opus: "D",
    number: "26",
    title: "That Certain Smile",
    date: "May 1940",
    scoring: "Dance Band",
  },
  {
    id: "106",
    opus: "7",
    number: "1",
    title: "Tone Paintings for Woodwinds (Evening Waters)",
    date: "June 1940",
    scoring: "Woodwind Choir",
  },
  {
    id: "107",
    opus: "7",
    number: "2",
    title: "Tone Paintings for Woodwinds (Dancing Peasants)",
    date: "June 1940",
    scoring: "Woodwind Choir",
  },
  {
    id: "108",
    opus: "E",
    number: "8",
    title: "Clair de Lune",
    date: "July 1940",
    scoring: "Brass Choir and Harp",
  },
  {
    id: "109",
    opus: "D",
    number: "27",
    title: "Goodnight Sweetheart (No. 3)",
    date: "August 1940",
    scoring: "Dance Band",
  },
  {
    id: "110",
    opus: "X",
    number: "14",
    title: "When Our Wishing Star Comes Back",
    date: "September 1940",
    scoring: "Popular Song",
  },
  {
    id: "111",
    opus: "11",
    number: "1b",
    title: "Prelude (Transcription of Awakening)",
    date: "November 1940",
    scoring: "Piano",
  },
  {
    id: "112",
    opus: "11",
    number: "1a",
    title: "Awakening",
    date: "November 1940",
    scoring: "Song",
  },
  {
    id: "113",
    opus: "G",
    number: "2",
    title: "I Love You Truly",
    date: "November 1940",
    scoring: "Male Quartet",
  },
  {
    id: "114",
    opus: "C",
    number: "2",
    title: "Londonderry Aire",
    date: "January 1941",
    scoring: "Chorus (A Capella)",
  },
  {
    id: "115",
    opus: "C",
    number: "3",
    title: "I’ll Never Smile Again",
    date: "April 1941",
    scoring: "Mixed Quintet",
  },
  {
    id: "116",
    opus: "D",
    number: "28",
    title: "When Our Wishing Star Comes Back",
    date: "April 1941",
    scoring: "Dance Band",
  },
  {
    id: "117",
    opus: "11",
    number: "2",
    title: "Obedience",
    date: "May 1941",
    scoring: "Mixed Chorus and Piano",
  },
  {
    id: "118",
    opus: "C",
    number: "4",
    title: "Stardust",
    date: "May 1941",
    scoring: "Mixed Quintet",
  },
  {
    id: "119",
    opus: "C",
    number: "5",
    title: "Moonlight Serenade",
    date: "May 1941",
    scoring: "Mixed Quintet",
  },
  {
    id: "120",
    opus: "F",
    number: "1",
    title: "Oh My Father",
    date: "May 1941",
    scoring: "Female Quartet",
  },
  {
    id: "121",
    opus: "G",
    number: "3",
    title: "Stardust No. 1",
    date: "May 1941",
    scoring: "Male Chorus (8-Part)",
  },
  {
    id: "122",
    opus: "C",
    number: "6",
    title: "Hut Sut Song",
    date: "June 1941",
    scoring: "Mixed Quintet",
  },
  {
    id: "123",
    opus: "E",
    number: "9",
    title: "Three Little Fishes",
    date: "July 1941",
    scoring: "Trumpet Trio",
  },
  {
    id: "124",
    opus: "10G",
    number: "24",
    title: "Sweet and Low",
    date: "October 1941",
    scoring: "Male Chorus",
  },
  {
    id: "125",
    opus: "10G",
    number: "2",
    title: "Though Deep’ning Trials",
    date: "August 1941",
    scoring: "Male Chorus",
  },
  {
    id: "126",
    opus: "10G",
    number: "3",
    title: "Gently Raise the Sacred Strain",
    date: "August 1941",
    scoring: "Male Chorus",
  },
  {
    id: "127",
    opus: "10G",
    number: "18",
    title: "Steal Away to Jesus",
    date: "August 1941",
    scoring: "Male Chorus",
  },
  {
    id: "128",
    opus: "10G",
    number: "19",
    title: "Swing Low Sweet Chariot",
    date: "August 1941",
    scoring: "Male Chorus",
  },
  {
    id: "129",
    opus: "X",
    number: "15",
    title: "Meltin’ With the Melodiers",
    date: "August 1941",
    scoring: "Popular Song",
  },
  {
    id: "130",
    opus: "10G",
    number: "4",
    title: "O God The Eternal Father",
    date: "August 1941",
    scoring: "Male Chorus",
  },
  {
    id: "131",
    opus: "10G",
    number: "12",
    title: "True to the Faith",
    date: "August 1941",
    scoring: "Male Chorus",
  },
  {
    id: "132",
    opus: "10G",
    number: "14",
    title: "O Lamb of God",
    date: "August 1941",
    scoring: "Male Chorus",
  },
  {
    id: "133",
    opus: "10G",
    number: "31",
    title: "Swannee River",
    date: "August 1941",
    scoring: "Male Chorus",
  },
  {
    id: "134",
    opus: "10G",
    number: "5",
    title: "Joseph Smith’s First Prayer",
    date: "September 1941",
    scoring: "Male Chorus",
  },
  {
    id: "135",
    opus: "10G",
    number: "10",
    title: "In Remembrance of Thy Suff’ring",
    date: "September 1941",
    scoring: "Male Chorus",
  },
  {
    id: "136",
    opus: "10G",
    number: "23",
    title: "Drink to Me Only With Thine Eyes",
    date: "September 1941",
    scoring: "Male Chorus",
  },
  {
    id: "137",
    opus: "X",
    number: "16",
    title: "Can’t Seem to Make Up My Mind",
    date: "September 1941",
    scoring: "Popular Song",
  },
  {
    id: "138",
    opus: "10G",
    number: "7",
    title: "The Lord Is My Shepherd",
    date: "October 1941",
    scoring: "Male Chorus",
  },
  {
    id: "139",
    opus: "10G",
    number: "25",
    title: "John Peel",
    date: "October 1941",
    scoring: "Male Chorus",
  },
  {
    id: "140",
    opus: "10G",
    number: "26",
    title: "Lullaby (Quintet)",
    date: "October 1941",
    scoring: "Male Chorus",
  },
  {
    id: "141",
    opus: "10G",
    number: "8",
    title: "As the Dew From Heav’n Distilling",
    date: "November 1941",
    scoring: "Male Chorus",
  },
  {
    id: "142",
    opus: "10G",
    number: "21",
    title: "America the Beautiful",
    date: "November 1941",
    scoring: "Male Chorus",
  },
  {
    id: "143",
    opus: "F",
    number: "5",
    title: "Can’t Seem to Make Up My Mind",
    date: "November 1941",
    scoring: "Female Quartet",
  },
  {
    id: "144",
    opus: "10",
    number: "1",
    title: "The Chant of Zarahemla",
    date: "December 1941",
    scoring: "Male Chorus",
  },
  {
    id: "145",
    opus: "10G",
    number: "15",
    title: "Far Far Away on Judea’s Plains",
    date: "December 1941",
    scoring: "Male Chorus",
  },
  {
    id: "146",
    opus: "10G",
    number: "16",
    title: "O Come All Ye Faithful",
    date: "December 1941",
    scoring: "Male Chorus",
  },
  {
    id: "147",
    opus: "10G",
    number: "17",
    title: "Jingle Bells",
    date: "December 1941",
    scoring: "Male Chorus",
  },
  {
    id: "148",
    opus: "10G",
    number: "34",
    title: "Thee Blind Mice",
    date: "January 1, 1942",
    scoring: "Male Chorus",
  },
  {
    id: "149",
    opus: "10G",
    number: "22",
    title: "God Bless America",
    date: "January 1942",
    scoring: "Male Chorus",
  },
  {
    id: "150",
    opus: "A",
    number: "2",
    title: "As the Dew From Heav’n Distilling",
    date: "January 1942",
    scoring: "Piano",
  },
  {
    id: "151",
    opus: "B",
    number: "25",
    title: "Stardust",
    date: "January 1942",
    scoring: "Voice (Tenor) and Piano",
  },
  {
    id: "152",
    opus: "D",
    number: "29",
    title: "Can’t Seem to Make Up My Mind",
    date: "February 1942",
    scoring: "Dance Band",
  },
  {
    id: "153",
    opus: "10",
    number: "2",
    title: "Christ Arose Victorious",
    date: "March 1942",
    scoring: "Male Chorus",
  },
  {
    id: "154",
    opus: "10G",
    number: "6",
    title: "Abide With Me",
    date: "March 1942",
    scoring: "Male Chorus",
  },
  {
    id: "155",
    opus: "G",
    number: "4",
    title: "Smoke Gets in Your Eyes",
    date: "March 1942",
    scoring: "Male Chorus",
  },
  {
    id: "156",
    opus: "10G",
    number: "9",
    title: "Oh It Is Wonderful",
    date: "April 1942",
    scoring: "Male Chorus",
  },
  {
    id: "157",
    opus: "10",
    number: "3",
    title: "Another Day Has Passed and Gone",
    date: "April 1942",
    scoring: "Male Chorus",
  },
  {
    id: "158",
    opus: "10",
    number: "4",
    title: "O Lord Preserve Thy Chosen Seed",
    date: "April 1942",
    scoring: "Male Chorus",
  },
  {
    id: "159",
    opus: "10",
    number: "5",
    title: "By the Rivers of Babylon",
    date: "April 1942",
    scoring: "Male Chorus and Soprano",
  },
  {
    id: "160",
    opus: "10",
    number: "6",
    title: "The God That Others Worship",
    date: "April 1942",
    scoring: "Male Chorus",
  },
  {
    id: "161",
    opus: "10",
    number: "7",
    title: "The Happy Mormon",
    date: "April 1942",
    scoring: "Male Chorus",
  },
  {
    id: "162",
    opus: "10G",
    number: "32",
    title: "Home Sweet Home",
    date: "April - June 1942",
    scoring: "Male Chorus",
  },
  {
    id: "163",
    opus: "C",
    number: "7",
    title: "White Cliffs of Dover",
    date: "April 1942",
    scoring: "Mixed Chorus",
  },
  {
    id: "164",
    opus: "10G",
    number: "11",
    title: "We Thank Thee Oh God For a Prophet",
    date: "April 1942",
    scoring: "Male Chorus",
  },
  {
    id: "165",
    opus: "10G",
    number: "33",
    title: "Home On the Range",
    date: "May 1942",
    scoring: "Male Chorus",
  },
  {
    id: "166",
    opus: "G",
    number: "5",
    title: "School Days",
    date: "May 1942",
    scoring: "Male Quartet",
  },
  {
    id: "167",
    opus: "10G",
    number: "1",
    title: "The Challenge",
    date: "May 1942",
    scoring: "Male Chorus",
  },
  {
    id: "168",
    opus: "10G",
    number: "13",
    title: "Our Mountain Home So Dear",
    date: "June 1942",
    scoring: "Male Chorus",
  },
  {
    id: "169",
    opus: "G",
    number: "6",
    title: "Don’t Sit Under the Apple Tree",
    date: "June 1942",
    scoring: "Male Quartet",
  },
  {
    id: "170",
    opus: "10G",
    number: "30",
    title: "Old Black Joe",
    date: "June 1942",
    scoring: "Male Chorus",
  },
  {
    id: "171",
    opus: "11",
    number: "3",
    title: "Welcome Song",
    date: "July 1942",
    scoring: "Male Chorus",
  },
  {
    id: "172",
    opus: "10G",
    number: "20",
    title: "Nobody Knows De Trouble",
    date: "July 1942",
    scoring: "Male Chorus",
  },
  {
    id: "173",
    opus: "10G",
    number: "27",
    title: "Salut D’Amour",
    date: "July 1942",
    scoring: "Male Chorus",
  },
  {
    id: "174",
    opus: "10G",
    number: "28",
    title: "Annie Laurie",
    date: "July 1942",
    scoring: "Male Chorus",
  },
  {
    id: "175",
    opus: "G",
    number: "7",
    title: "Londonderry Aire",
    date: "August 1942",
    scoring: "Male Chorus and Strings",
  },
  {
    id: "176",
    opus: "G",
    number: "12",
    title: "As The Dew From Heav’n Distilling",
    date: "August 1942",
    scoring: "Male Chorus",
  },
  {
    id: "177",
    opus: "10G",
    number: "29",
    title: "Loch Lomond",
    date: "August 1942",
    scoring: "Male Chorus",
  },
  {
    id: "178",
    opus: "11",
    number: "4",
    title: "Pastorale",
    date: "September 1942",
    scoring: "Chorus (A Capella)",
  },
  {
    id: "179",
    opus: "F",
    number: "2",
    title: "As the Dew From Heav’n Distilling",
    date: "October 1942",
    scoring: "Female Trio",
  },
  {
    id: "180",
    opus: "F",
    number: "6",
    title: "At Last",
    date: "October 1942",
    scoring: "Female Quintet",
  },
  {
    id: "181",
    opus: "X",
    number: "17",
    title: "You Are My Symphony",
    date: "November 1942",
    scoring: "Popular Song",
  },
  {
    id: "182",
    opus: "B",
    number: "14",
    title: "When Johnny Goes Marching Home",
    date: "November 1942",
    scoring: "Solo Voice",
  },
  {
    id: "183",
    opus: "E",
    number: "10",
    title: "Hail, San Jose",
    date: "November 1942",
    scoring: "Marching Band",
  },
  {
    id: "184",
    opus: "F",
    number: "3",
    title: "The Lord Is My Shepherd",
    date: "November 1942",
    scoring: "Female Trio",
  },
  {
    id: "185",
    opus: "C",
    number: "14",
    title: "Nobody Knows De Trouble I Seen",
    date: "December 1942",
    scoring: "Chorus (A Capella)",
  },
  {
    id: "186",
    opus: "F",
    number: "4",
    title: "Love At Home",
    date: "December 1942",
    scoring: "Female Trio",
  },
  {
    id: "187",
    opus: "F",
    number: "7",
    title: "Manhattan Serenade",
    date: "January 1943",
    scoring: "Female Quintet",
  },
  {
    id: "188",
    opus: "C",
    number: "8",
    title: "You Are My Symphony",
    date: "February 1943",
    scoring: "Mixed Quintet",
  },
  {
    id: "189",
    opus: "C",
    number: "9",
    title: "I Wrote A Song",
    date: "February 1943",
    scoring: "Mixed Quintet",
  },
  {
    id: "190",
    opus: "F",
    number: "8",
    title: "There Are Such Things",
    date: "February 1943",
    scoring: "Female Quintet",
  },
  {
    id: "191",
    opus: "B",
    number: "15",
    title: "Army Song",
    date: "March 1943",
    scoring: "Solo Voice",
  },
  {
    id: "192",
    opus: "C",
    number: "10",
    title: "Moonlight Mood",
    date: "March 1943",
    scoring: "Mixed Quintet",
  },
  {
    id: "193",
    opus: "D",
    number: "30",
    title: "I Wrote A Song",
    date: "March 1943",
    scoring: "Dance Band",
  },
  {
    id: "194",
    opus: "11",
    number: "5",
    title: "Invention",
    date: "July 1943",
    scoring: "Piano",
  },
  {
    id: "195",
    opus: "E",
    number: "11",
    title: "Service Song Medley",
    date: "July 1943",
    scoring: "Piano (2)",
  },
  {
    id: "196",
    opus: "11",
    number: "6",
    title: "Canon",
    date: "August 1943",
    scoring: "Piano",
  },
  {
    id: "197",
    opus: "D",
    number: "31",
    title: "I Know Why",
    date: "August 1943",
    scoring: "Dance Band",
  },
  {
    id: "198",
    opus: "E",
    number: "12",
    title: "Drink to Me Only With Thine Eyes",
    date: "August 1943",
    scoring: "Clarinet Quartet",
  },
  {
    id: "199",
    opus: "E",
    number: "13",
    title: "Oh! Susannah",
    date: "August 1943",
    scoring: "Woodwind Quintet",
  },
  {
    id: "200",
    opus: "E",
    number: "14",
    title: "Three Blind Mice",
    date: "August 1943",
    scoring: "Brass Quartet",
  },
  {
    id: "201",
    opus: "11",
    number: "7",
    title: "Invention",
    date: "September 1943",
    scoring: "Piano",
  },
  {
    id: "202",
    opus: "12",
    number: "",
    title: "V-12 Victory Song",
    date: "September 1943",
    scoring: "Male Chorus and Band",
  },
  {
    id: "203",
    opus: "13",
    number: "1",
    title: "Love Theme From Camelot",
    date: "September 1943",
    scoring: "Piano",
  },
  {
    id: "204",
    opus: "X",
    number: "18",
    title: "I’m On The Dead Watch",
    date: "September 1943",
    scoring: "Popular Song",
  },
  {
    id: "205",
    opus: "X",
    number: "19",
    title: "That’s Taboo",
    date: "September 1943",
    scoring: "Popular Song",
  },
  {
    id: "206",
    opus: "C",
    number: "11",
    title: "O Sacred Head Now Wounded",
    date: "September 1943",
    scoring: "Chorus",
  },
  {
    id: "207",
    opus: "D",
    number: "32",
    title: "Eight Breaks For Stage Band",
    date: "September 1943",
    scoring: "Dance Band",
  },
  {
    id: "208",
    opus: "E",
    number: "15",
    title: "Londonderry Aire",
    date: "September 1943",
    scoring: "String Quartet",
  },
  {
    id: "209",
    opus: "E",
    number: "16",
    title: "Ballet Music From Faust (Excerpts)",
    date: "September 1943",
    scoring: "Chamber Orchestra",
  },
  {
    id: "210",
    opus: "G",
    number: "8",
    title: "There Are Such Things",
    date: "September 1943",
    scoring: "Male Quartet",
  },
  {
    id: "211",
    opus: "11",
    number: "8",
    title: "Invention",
    date: "October 1943",
    scoring: "Piano",
  },
  {
    id: "212",
    opus: "E",
    number: "17",
    title: "Tea For Two (Reduction)",
    date: "October 1943",
    scoring: "Concert Band",
  },
  {
    id: "213",
    opus: "E",
    number: "18",
    title: "I Heard You Cried Last Night",
    date: "October 1943",
    scoring: "Saxophone Quintet",
  },
  {
    id: "214",
    opus: "G",
    number: "9",
    title: "Star Dust No.2",
    date: "March 1944",
    scoring: "Male Chorus",
  },
  {
    id: "215",
    opus: "G",
    number: "10",
    title: "All The Things You Are",
    date: "March 1944",
    scoring: "Male Chorus",
  },
  {
    id: "216",
    opus: "G",
    number: "11",
    title: "Anchors Aweigh",
    date: "March 1944",
    scoring: "Male Chorus",
  },
  {
    id: "217",
    opus: "B",
    number: "16",
    title: "Forward America",
    date: "April 1944",
    scoring: "Solo Voice",
  },
  {
    id: "218",
    opus: "C",
    number: "12",
    title: "I Love You",
    date: "April 1944",
    scoring: "Chorus",
  },
  {
    id: "219",
    opus: "C",
    number: "13",
    title: "Service Song Medley",
    date: "April 1944",
    scoring: "Chorus",
  },
  {
    id: "220",
    opus: "F",
    number: "9",
    title: "It’s Love, Love Love",
    date: "April 25, 1944",
    scoring: "Female Trio",
  },
  {
    id: "221",
    opus: "X",
    number: "20",
    title: "When I Saw You",
    date: "September 1944",
    scoring: "Popular Song",
  },
  {
    id: "222",
    opus: "9",
    number: "",
    title: "Variations On An Unemotional Theme - Variation 1: Gaiety",
    date: "May 1939 - January 1, 1945",
    scoring: "Strings",
  },
  {
    id: "223",
    opus: "9",
    number: "",
    title: "Variations On An Unemotional Theme - Variation 2: Sentiment",
    date: "May 1939 - January 1, 1945",
    scoring: "Strings",
  },
  {
    id: "224",
    opus: "9",
    number: "",
    title: "Variations On An Unemotional Theme - Variation 3: Death",
    date: "May 1939 - January 1, 1945",
    scoring: "Strings",
  },
  {
    id: "225",
    opus: "9",
    number: "",
    title: "Variations On An Unemotional Theme - Variation 4: Farce",
    date: "May 1939 - January 1, 1945",
    scoring: "Strings",
  },
  {
    id: "226",
    opus: "9",
    number: "",
    title: "Variations On An Unemotional Theme - Variation 5: Disappointment",
    date: "May 1939 - January 1, 1945",
    scoring: "Strings",
  },
  {
    id: "227",
    opus: "9",
    number: "",
    title: "Variations On An Unemotional Theme - Variation 6: Daydream",
    date: "May 1939 - January 1, 1945",
    scoring: "Strings",
  },
  {
    id: "228",
    opus: "9",
    number: "",
    title: "Variations On An Unemotional Theme - Variation 7: Finale",
    date: "May 1939 - January 1, 1945",
    scoring: "Strings",
  },
  {
    id: "229",
    opus: "G",
    number: "13",
    title: "Silent Night",
    date: "December 1944",
    scoring: "Male Chorus",
  },
  {
    id: "230",
    opus: "H",
    number: "1",
    title: "Dedication (Through the Looking Glass)",
    date: "March 1945",
    scoring: "Orchestra",
  },
  {
    id: "231",
    opus: "F",
    number: "10",
    title: "Love At Home",
    date: "May 1945",
    scoring: "Female Trio/Quartet",
  },
  {
    id: "232",
    opus: "14",
    number: "1",
    title: "May The Lord Go With Us",
    date: "July 12, 1945",
    scoring: "Hymn",
  },
  {
    id: "233",
    opus: "14",
    number: "2",
    title: "How Beauteous Are Their Feet",
    date: "July 19, 1945",
    scoring: "Hymn",
  },
  {
    id: "234",
    opus: "14",
    number: "3",
    title: "Savior, Redeemer of My Soul",
    date: "July 27, 1945",
    scoring: "Hymn",
  },
  {
    id: "235",
    opus: "14",
    number: "4",
    title: "Lord God of Majesty and Might",
    date: "August 22, 1945",
    scoring: "Hymn",
  },
  {
    id: "236",
    opus: "14",
    number: "5",
    title: "Ring Out Wild Bells",
    date: "August 23, 1945",
    scoring: "Carol",
  },
  {
    id: "237",
    opus: "F",
    number: "11",
    title: "Oh It Is Wonderful",
    date: "August 1945",
    scoring: "Female Trio",
  },
  {
    id: "238",
    opus: "14",
    number: "6",
    title: "Tune Thy Harps, O Israel",
    date: "September 4, 1945",
    scoring: "Hymn",
  },
  {
    id: "239",
    opus: "14",
    number: "7",
    title: "The Lord Be With Us As We Walk",
    date: "September 5, 1945",
    scoring: "Hymn",
  },
  {
    id: "240",
    opus: "14",
    number: "8",
    title: "Great King of Heaven",
    date: "September 5, 1945",
    scoring: "Hymn",
  },
  {
    id: "241",
    opus: "14",
    number: "9",
    title: "See All Creation Joins",
    date: "September 12, 1945",
    scoring: "Hymn",
  },
  {
    id: "242",
    opus: "14",
    number: "10",
    title: "We Love Thy House, O God",
    date: "September 14, 1945",
    scoring: "Hymn",
  },
  {
    id: "243",
    opus: "14",
    number: "11",
    title: "Lord We Come Before Thee Now",
    date: "September 19, 1945",
    scoring: "Hymn",
  },
  {
    id: "244",
    opus: "14",
    number: "12",
    title: "Lord, Accept Into Thy Kingdom",
    date: "September 21, 1945",
    scoring: "Hymn",
  },
  {
    id: "245",
    opus: "14",
    number: "13",
    title: "Upon The Cross of Calvary",
    date: "September 27, 1945",
    scoring: "Hymn",
  },
  {
    id: "246",
    opus: "14",
    number: "14",
    title: "God of Power, God of Right",
    date: "October 11, 1945",
    scoring: "Hymn",
  },
  {
    id: "247",
    opus: "G",
    number: "14",
    title: "Abide With Me (No. 2)",
    date: "September 30, 1945",
    scoring: "Male Chorus",
  },
  {
    id: "248",
    opus: "F",
    number: "12",
    title: "Smoke Gets in Your Eyes",
    date: "November 1945",
    scoring: "Female (Quintet)",
  },
  {
    id: "249",
    opus: "F",
    number: "13",
    title: "When You Wish Upon A Star",
    date: "December 28, 1945",
    scoring: "Female (Quartet)",
  },
  {
    id: "250",
    opus: "15",
    number: "",
    title: "King Kole’s Kourt Suite No. 1: Little Boy Blue",
    date: "January 3-5, 1946",
    scoring: "Female Chorus (SSA) and Piano",
  },
  {
    id: "251",
    opus: "15",
    number: "",
    title: "King Kole’s Kourt Suite No. 2: Three Blind Mice",
    date: "January 3-5, 1946",
    scoring: "Female Chorus (SSA) and Piano",
  },
  {
    id: "252",
    opus: "15",
    number: "",
    title: "King Kole’s Kourt Suite No. 3: Humpty Dumpty",
    date: "January 3-5, 1946",
    scoring: "Female Chorus (SSA) and Piano",
  },
  {
    id: "253",
    opus: "15",
    number: "",
    title: "King Kole’s Kourt Suite No. 4: Little Miss Muffet",
    date: "January 3-5, 1946",
    scoring: "Female Chorus (SSA) and Piano",
  },
  {
    id: "254",
    opus: "15",
    number: "",
    title:
      "King Kole’s Kourt Suite No. 5: There Was an Old Woman Who Lived In a Shoe",
    date: "January 3-5, 1946",
    scoring: "Female Chorus (SSA) and Piano",
  },
  {
    id: "255",
    opus: "F",
    number: "14",
    title: "In the Garden",
    date: "January 13, 1946",
    scoring: "Female Chorus (SSAA) and Piano",
  },
  {
    id: "256",
    opus: "F",
    number: "15",
    title: "Joseph Smith’s First Prayer",
    date: "January 29, 1946",
    scoring: "Female Chorus (SSA) and Piano",
  },
  {
    id: "257",
    opus: "F",
    number: "16",
    title: "Louise",
    date: "February 12, 1946",
    scoring: "Female Chorus (SSA) and Piano",
  },
  {
    id: "258",
    opus: "16",
    number: "",
    title: "Hora Breve",
    date: "January 17 - March 23, 1946",
    scoring: "Chorus and Strings",
  },
  {
    id: "259",
    opus: "F",
    number: "17",
    title: "Moonlove",
    date: "April 19, 1946",
    scoring: "Female Chorus (SSAA) and Piano",
  },
  {
    id: "260",
    opus: "F",
    number: "18",
    title: "Full Moon and Empty Arms",
    date: "April 29, 1946",
    scoring: "Female Chorus (SSAA) and Piano",
  },
  {
    id: "261",
    opus: "F",
    number: "19",
    title: "For My Mother  (Malotte)",
    date: "May 5, 1946",
    scoring: "Female Chorus",
  },
  {
    id: "262",
    opus: "F",
    number: "20",
    title: "My God and I (Austris A, Wihtol)",
    date: "May 14, 1946",
    scoring: "Female Chorus",
  },
  {
    id: "263",
    opus: "17",
    number: "1",
    title: "Relief Society Skit Music",
    date: "May 19, 1946",
    scoring: "(song)",
  },
  {
    id: "264",
    opus: "3",
    number: "3",
    title: "Unknown Title",
    date: "May 20, 1946",
    scoring: "Piano",
  },
  {
    id: "265",
    opus: "H",
    number: "2",
    title: "Rachmaninoff 2nd Symphony 3rd Movement excerpt",
    date: "May 22, 1946",
    scoring: "Orchestra Reduction",
  },
  {
    id: "266",
    opus: "X",
    number: "21",
    title: "Songs at Noontime",
    date: "Unknown",
    scoring: "Popular Song",
  },
  {
    id: "267",
    opus: "F",
    number: "21",
    title: "Sing, Glad Heart",
    date: "June 17, 1946",
    scoring: "Female Chorus",
  },
  {
    id: "268",
    opus: "G",
    number: "15",
    title: "All The Things You Are (No. 2)",
    date: "July 22, 1946",
    scoring: "Male Chorus",
  },
  {
    id: "269",
    opus: "X",
    number: "22",
    title: "No Title",
    date: "July 23, 1946",
    scoring: "Popular Song",
  },
  {
    id: "270",
    opus: "KSL",
    number: "1",
    title: "All the Things You Are",
    date: "August 10, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "271",
    opus: "KSL",
    number: "2",
    title: "All Through the Day",
    date: "August 14, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "272",
    opus: "KSL",
    number: "3",
    title: "If I Loved You (Richard Rogers)",
    date: "August 22, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "273",
    opus: "KSL",
    number: "4",
    title: "Dancing in the Dark",
    date: "August 20, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "274",
    opus: "KSL",
    number: "5",
    title: "Over the Rainbow",
    date: "August 24, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "275",
    opus: "KSL",
    number: "6",
    title: "The Way You Look Tonight (Jerome Kern)",
    date: "September 2, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "276",
    opus: "17",
    number: "2",
    title: "Theme Music for KSL Orchestra Title;",
    date: "September 2, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "277",
    opus: "KSL",
    number: "7",
    title: "Theme Music (above--arrangement)",
    date: "September 2, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "278",
    opus: "KSL",
    number: "8",
    title: "Clair De Lune (Claude Debussy)",
    date: "September 5, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "279",
    opus: "KSL",
    number: "9",
    title: "Night and Day (Cole Porter)",
    date: "September 9, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "280",
    opus: "KSL",
    number: "10",
    title: "Embraceable You (George Gershwin)",
    date: "September 13, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "281",
    opus: "KSL",
    number: "11",
    title: "In Love in Vain (Jerome Kern)",
    date: "September 17, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "282",
    opus: "KSL",
    number: "12",
    title: "They Say It’s Wonderful",
    date: "September 18, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "283",
    opus: "KSL",
    number: "13",
    title: "Where or When",
    date: "September 24, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "284",
    opus: "KSL",
    number: "14",
    title: "Etude Opus 10 No. 3. (Frederic Chopin)",
    date: "September 26, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "285",
    opus: "KSL",
    number: "15",
    title: "Romance (Rubinstein)",
    date: "September 26, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "286",
    opus: "KSL",
    number: "16",
    title: "Begin the Beguine",
    date: "October 8, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "287",
    opus: "KSL",
    number: "17",
    title: "Donkey Serenade (Friml-Stothart)",
    date: "October 18, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "288",
    opus: "KSL",
    number: "18",
    title: "Long Ago and Far Away",
    date: "October 25, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "289",
    opus: "KSL",
    number: "19",
    title: "I’ve Got You Under My Skin (Porter)",
    date: "October 26, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "290",
    opus: "G",
    number: "16",
    title: "I’ll Go Where You Want Me to Go",
    date: "November 4, 1946",
    scoring: "Male Chorus",
  },
  {
    id: "291",
    opus: "KSL",
    number: "20",
    title: "I’ll See You Again (Noel Coward)",
    date: "November 7, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "292",
    opus: "G",
    number: "17",
    title: "The Dream Girl of Delta Phi",
    date: "November 11, 1946",
    scoring: "Male Chorus",
  },
  {
    id: "293",
    opus: "G",
    number: "18",
    title: "Hail, Utah",
    date: "November 27, 1946",
    scoring: "Male Chorus",
  },
  {
    id: "294",
    opus: "KSL",
    number: "21",
    title: "I Love You (Cole Porter)",
    date: "November 14, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "295",
    opus: "X",
    number: "23",
    title: "The Dream Girl of Delta Phi (New Chorus)",
    date: "November 9, 1946",
    scoring: "Popular Song",
  },
  {
    id: "296",
    opus: "G",
    number: "19",
    title: "The Dream Girl of Delta Phi (Original Version)",
    date: "November 9, 1946",
    scoring: "Male Chorus",
  },
  {
    id: "297",
    opus: "KSL",
    number: "22",
    title: "What Is This Thing Called Love? (Cole Porter)",
    date: "November 22, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "298",
    opus: "KSL",
    number: "23",
    title: "Temptation",
    date: "November 29, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "299",
    opus: "17",
    number: "3",
    title: "Our Pioneers (Eliza Meacham)",
    date: "November 26, 1946",
    scoring: "Unknown",
  },
  {
    id: "300",
    opus: "KSL",
    number: "24",
    title: "In the Still of the Night (Cole Porter)",
    date: "December 3, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "301",
    opus: "KSL",
    number: "25",
    title:
      "Christmas Medley (Let it Snow, Santa Claus is coming to town, White Christmas, Winter wonderland)",
    date: "December 17, 1946",
    scoring: "Chamber Orchestra",
  },
  {
    id: "302",
    opus: "17",
    number: "4",
    title:
      "A Thing of Beauty Is a Joy Forever (Theme Music for New KSL Orchestra Show with Bennett Glass)",
    date: "January 21, 1947",
    scoring: "Chamber Orchestra",
  },
  {
    id: "303",
    opus: "KSL",
    number: "26",
    title: "A Thing of Beauty",
    date: "January 21, 1947",
    scoring: "Chamber Orchestra",
  },
  {
    id: "304",
    opus: "18",
    number: "",
    title: "Ode to Acacia (Concerto for Piano and Orchestra)--(Master Thesis)",
    date: "July 1943 - February 1947",
    scoring: "Piano Concerto",
  },
  {
    id: "305",
    opus: "G",
    number: "20",
    title: "Dream Girl of Delta Phi No. 3",
    date: "March 1947",
    scoring: "Male Chorus and Piano",
  },
  {
    id: "306",
    opus: "X",
    number: "24",
    title: "The Trek West",
    date: "May 1947",
    scoring: "Popular Song",
  },
  {
    id: "307",
    opus: "19",
    number: "",
    title: "Promised Valley (Utah Centennial Music-Drama)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "308",
    opus: "19",
    number: "1",
    title: "Prelude to Act 1",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "309",
    opus: "19",
    number: "2",
    title: "Prologue (The Winter Quarters Scene",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "310",
    opus: "19",
    number: "3",
    title: "Transition Music (Scenes 1 to 2)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "311",
    opus: "19",
    number: "4",
    title: "Background #1",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "312",
    opus: "19",
    number: "5",
    title: "The Shirt Song (Fennelly and Sons)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "313",
    opus: "19",
    number: "6",
    title: "Background #2",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "314",
    opus: "19",
    number: "7",
    title: "The Wind is a Lion (Celia)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "315",
    opus: "19",
    number: "8",
    title: "Background #2 1/2",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "316",
    opus: "19",
    number: "9",
    title: "Love is my Song (Celia, Jed and Chorus)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "317",
    opus: "19",
    number: "10",
    title: "Background #3",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "318",
    opus: "19",
    number: "11",
    title: "Valley Home (Jed)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "319",
    opus: "19",
    number: "12",
    title: "Background #4",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "320",
    opus: "19",
    number: "13",
    title: "Background #5",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "321",
    opus: "19",
    number: "14",
    title: "Background #6",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "322",
    opus: "19",
    number: "15",
    title: "Upper California (Dance and Chorus)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "323",
    opus: "19",
    number: "16",
    title: "Sparking on a Sunday Night (Duet)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "324",
    opus: "19",
    number: "17",
    title: "Background #7",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "325",
    opus: "19",
    number: "18",
    title: "The Wind is a Lion (reprise #1)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "326",
    opus: "19",
    number: "19",
    title: "Departure Music",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "327",
    opus: "19",
    number: "20",
    title: "Transition Music (Scenes 2 to 3)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "328",
    opus: "19",
    number: "21",
    title: "Come, Come, Ye Saints (3 verses - Chorus)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "329",
    opus: "19",
    number: "22",
    title: "The Wind is a Lion (reprise #2)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "330",
    opus: "19",
    number: "23",
    title: "Background #8",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "331",
    opus: "19",
    number: "24",
    title: "My Heart is Lost and Lonely (Celia, Jed)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "332",
    opus: "19",
    number: "25",
    title: "Background #9",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "333",
    opus: "19",
    number: "26",
    title: "Background #10",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "334",
    opus: "19",
    number: "27",
    title: "Bakground #11",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "335",
    opus: "19",
    number: "28",
    title: "Come, Come, Ye Saints (3 verses - Chorus)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "336",
    opus: "19",
    number: "29",
    title: "Background #12",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "337",
    opus: "19",
    number: "30",
    title: "Transition Music (Scenes 3 to 4)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "338",
    opus: "19",
    number: "31",
    title: "Background #13",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "339",
    opus: "19",
    number: "32",
    title: "The Cushioned Seat (Fennelly and Sons)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "340",
    opus: "19",
    number: "33",
    title: "Background #14",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "341",
    opus: "19",
    number: "34",
    title: "This is the Place (Dance, Chorus and Baritone)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "342",
    opus: "19",
    number: "35",
    title: "Prelude to Act 2",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "343",
    opus: "19",
    number: "36",
    title: "Background #15",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "344",
    opus: "19",
    number: "37",
    title: "The Choir Practice Song (Chorus and Dance)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "345",
    opus: "19",
    number: "38",
    title: "Background #16",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "346",
    opus: "19",
    number: "39",
    title: "My Heart is Lost and Lonely Reprise (Celia)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "347",
    opus: "19",
    number: "40",
    title: "It's So Good to Be Home Again (Jed and Chorus)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "348",
    opus: "19",
    number: "41",
    title: "The Choir Practice Song Reprise",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "349",
    opus: "19",
    number: "42",
    title: "Love is My Song (Reprise)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "350",
    opus: "19",
    number: "43",
    title: "Background #17",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "351",
    opus: "19",
    number: "44",
    title: "Transition Music (Scenes 1 to 2)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "352",
    opus: "19",
    number: "45",
    title: "Think Not When You Gather to Zion",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "353",
    opus: "19",
    number: "46",
    title: "Background #18",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "354",
    opus: "19",
    number: "47",
    title: "Golly I'm Glad to Be Alive (Fennelly and Sons)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "355",
    opus: "19",
    number: "48",
    title: "Background #19",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "356",
    opus: "19",
    number: "49",
    title: "I Wonder Why (Jed)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "357",
    opus: "19",
    number: "50",
    title: "Indian Chant",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "358",
    opus: "19",
    number: "51",
    title: "Transition Music (Scenes 3 to 4)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "359",
    opus: "19",
    number: "52",
    title: "The Wind is a Lion Reprise #3 (Celia)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "360",
    opus: "19",
    number: "53",
    title: "Background #20",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "361",
    opus: "19",
    number: "54",
    title: "Cricket and Gull Scene (Jed, Chorus and Dance)",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "362",
    opus: "19",
    number: "55",
    title: "Epilogue The Fair Scene Finale",
    date: "January 6- July 21, 1947",
    scoring: "Musical Play",
  },
  {
    id: "363",
    opus: "G",
    number: "21",
    title: "The Wind Is a Lion",
    date: "1947",
    scoring: "Male Chorus",
  },
  {
    id: "364",
    opus: "G",
    number: "22",
    title: "Valley Home",
    date: "1947",
    scoring: "Male Quartet",
  },
  {
    id: "365",
    opus: "G",
    number: "23",
    title: "Golly I’m Glad to Be Alive",
    date: "1947",
    scoring: "Male Quartet",
  },
  {
    id: "366",
    opus: "G",
    number: "24",
    title: "West of the Wasatch",
    date: "1947",
    scoring: "Male Quartet",
  },
  {
    id: "367",
    opus: "G",
    number: "25",
    title: "Sunset Trail",
    date: "1947",
    scoring: "Male Quartet",
  },
  {
    id: "368",
    opus: "G",
    number: "26",
    title: "Cloverclub Potato Chips (No. 1)",
    date: "1947",
    scoring: "Male Quartet",
  },
  {
    id: "369",
    opus: "G",
    number: "27",
    title: "Cloverclub Potato Chips (No. 2)",
    date: "1947",
    scoring: "Male Quartet",
  },
  {
    id: "370",
    opus: "B",
    number: "17",
    title: "Love Is My Song",
    date: "1947",
    scoring: "Vocal",
  },
  {
    id: "371",
    opus: "B",
    number: "18",
    title: "Valley Home",
    date: "1947",
    scoring: "Vocal",
  },
  {
    id: "372",
    opus: "B",
    number: "19",
    title: "The Wind Is a Lion",
    date: "1947",
    scoring: "Vocal",
  },
  {
    id: "373",
    opus: "F",
    number: "22",
    title: "Nay Speak No Ill",
    date: "September 21, 1947",
    scoring: "Female Trio and Piano",
  },
  {
    id: "374",
    opus: "C",
    number: "15",
    title: "Valley Home",
    date: "September 22, 1947",
    scoring: "Mixed Chorus and Piano",
  },
  {
    id: "375",
    opus: "KSL",
    number: "27",
    title: "Falling in Love With Love",
    date: "October 13, 1947",
    scoring: "Chamber Orchestra",
  },
  {
    id: "376",
    opus: "G",
    number: "28",
    title: "Chopin’s Prelude Op. 28 No. 7",
    date: "October 16, 1947",
    scoring: "Male Chorus",
  },
  {
    id: "377",
    opus: "KSL",
    number: "28",
    title: "Frenesi",
    date: "November 3, 1947",
    scoring: "Chamber Orchestra",
  },
  {
    id: "378",
    opus: "17",
    number: "5",
    title: "Americana",
    date: "November 18, 1947",
    scoring: "Unknown",
  },
  {
    id: "379",
    opus: "KSL",
    number: "29",
    title: "The Continental",
    date: "December 1947",
    scoring: "Chamber Orchestra",
  },
  {
    id: "380",
    opus: "G",
    number: "29",
    title: "Every Nation of the World",
    date: "December 1947",
    scoring: "Male Chorus",
  },
  {
    id: "381",
    opus: "G",
    number: "30",
    title: "I’m Lonely Tonight",
    date: "December 1947",
    scoring: "Male Chorus",
  },
  {
    id: "382",
    opus: "G",
    number: "31",
    title: "White Christmas",
    date: "December 1947",
    scoring: "Male Chorus",
  },
  {
    id: "383",
    opus: "G",
    number: "32",
    title: "Silent Night",
    date: "December 1947",
    scoring: "Male Chorus",
  },
  {
    id: "384",
    opus: "C",
    number: "16",
    title: "Smoke Gets in Your Eyes",
    date: "January 18, 1948",
    scoring: "Female Chorus and Piano",
  },
  {
    id: "385",
    opus: "C",
    number: "18",
    title: "Lambda Delta Sigma Sweetheart",
    date: "January - April 1948",
    scoring: "Chorus",
  },
  {
    id: "386",
    opus: "C",
    number: "19",
    title: "Lambda Delta Sigma Hymn",
    date: "January - April 1948",
    scoring: "Chorus",
  },
  {
    id: "387",
    opus: "C",
    number: "17",
    title: "Oblivion",
    date: "March 20, 1948",
    scoring: "Chorus (SSAATTBB), Piano, and String Bass",
  },
  {
    id: "388",
    opus: "21",
    number: "1",
    title: "Chorale on a Theme (Leroy Robertson)",
    date: "",
    scoring: "Chorale",
  },
  {
    id: "389",
    opus: "20",
    number: "",
    title: "Choral Movement, Joy (Master's Thesis)",
    date: "",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "390",
    opus: "C",
    number: "20",
    title: "I’m in the Mood for Love",
    date: "",
    scoring: "BYU Dectet",
  },
  {
    id: "391",
    opus: "21",
    number: "2",
    title: "Centennial Days of 47 Fanfares (a) and (b)",
    date: "July 23, 1948",
    scoring: "Brass Choir",
  },
  {
    id: "392",
    opus: "D",
    number: "33",
    title: "Ravel’s Daphnis & Chloe",
    date: "July 24, 1948",
    scoring: "Stan Kenton Orchestra",
  },
  {
    id: "393",
    opus: "G",
    number: "33",
    title: "Whiffenpoof Song",
    date: "August 12, 1948",
    scoring: "Homer Adams Quartet",
  },
  {
    id: "394",
    opus: "X",
    number: "25",
    title: "Stockman’s Hotel Singing Commercial",
    date: "August 12, 1948",
    scoring: "Popular Song",
  },
  {
    id: "395",
    opus: "C",
    number: "21",
    title: "We’re in the MIA (Quentin Nordgren)",
    date: "",
    scoring: "Chorus",
  },
  {
    id: "396",
    opus: "C",
    number: "22",
    title: "Be With Us Evermore (Quentin Nordgren)",
    date: "",
    scoring: "Chorus",
  },
  {
    id: "397",
    opus: "21",
    number: "3",
    title: "Choral Harmonization and Fugal Exposition",
    date: "September 22, 1948",
    scoring: "Orchestra",
  },
  {
    id: "398",
    opus: "21",
    number: "4",
    title: "Harmonization of a Reinzi Theme in Wagner Style",
    date: "November 8, 1948",
    scoring: "Orchestra",
  },
  {
    id: "399",
    opus: "21",
    number: "5",
    title:
      "Short Composition Using Stylistic Elements of Wagner’s Tristan and Parsifal",
    date: "November 1948",
    scoring: "Orchestra",
  },
  {
    id: "400",
    opus: "21",
    number: "6",
    title: "Short Composition in Debussy Piano Style",
    date: "December 1948",
    scoring: "Piano",
  },
  {
    id: "401",
    opus: "C",
    number: "23",
    title:
      "Thanks for the Sabbath School  (Sunday School Conference April 1949)",
    date: "December 1948",
    scoring: "Chorus",
  },
  {
    id: "402",
    opus: "B",
    number: "20",
    title: "Italian Doll Carol",
    date: "December 1948",
    scoring: "Voice and Harp",
  },
  {
    id: "403",
    opus: "22",
    number: "1",
    title: "A Short Suite No. 1: Dance",
    date: "October 1948 - January 1949",
    scoring: "Orchestra",
  },
  {
    id: "404",
    opus: "22",
    number: "2",
    title: "A Short Suite No. 2: Matin",
    date: "October 1948 - January 1949",
    scoring: "Orchestra",
  },
  {
    id: "405",
    opus: "22",
    number: "3",
    title: "A Short Suite No. 3: Rondo",
    date: "October 1948 - January 1949",
    scoring: "Orchestra",
  },
  {
    id: "406",
    opus: "C",
    number: "24",
    title: "So In Love Am I (Cole Porter’s Kiss Me Kate)",
    date: "July 11, 1949",
    scoring: "Choral (SATB), bass, piano",
  },
  {
    id: "407",
    opus: "21",
    number: "8",
    title: "Quintet in the Style of Leroy Robertson",
    date: "May 1949",
    scoring: "Piano and Strings",
  },
  {
    id: "408",
    opus: "21",
    number: "9",
    title:
      "Concertino for Piano and Chamber Orchestra (Reorchestration of Opus 21 No. 8)",
    date: "May 1949",
    scoring: "Piano and Chamber Orchestra",
  },
  {
    id: "409",
    opus: "O",
    number: "1",
    title: "Ricercar in Six Voices (From the Musical Offering of J.S. Bach)",
    date: "September 1949",
    scoring: "Orchestra",
  },
  {
    id: "410",
    opus: "23",
    number: "1",
    title: "Chorale Prelude in 18th Century Style on O Sacred Head Now Wounded",
    date: "October 24, 1949",
    scoring: "Organ",
  },
  {
    id: "411",
    opus: "23",
    number: "2",
    title: "Chorale Prelude (Das Neugeborne Kindlein)",
    date: "November 2, 1949",
    scoring: "Organ",
  },
  {
    id: "412",
    opus: "23",
    number: "3",
    title: "Chorale Prelude (Als Jesus Christus in der Nacht)",
    date: "December 1, 1949",
    scoring: "Organ",
  },
  {
    id: "413",
    opus: "C",
    number: "25",
    title: "O No, John (English Folk Song)",
    date: "December 12, 1949",
    scoring: "Mixed Chorus and Piano",
  },
  {
    id: "414",
    opus: "23",
    number: "4",
    title:
      "Chorale Prelude in 18th Century Style on O Sacred Head Now Wounded No. 2",
    date: "January 5, 1950",
    scoring: "Organ",
  },
  {
    id: "415",
    opus: "23",
    number: "5",
    title:
      "Fugue (in 3 voices for piano--In 18th Century Style Theme from Marchant Collection",
    date: "February 2, 1950",
    scoring: "Piano",
  },
  {
    id: "416",
    opus: "23",
    number: "6a",
    title: "Miniatures for Flute and Three Strings: Meadows",
    date: "February 13, 1950",
    scoring: "Flute, Violin, Viola, Cello",
  },
  {
    id: "417",
    opus: "23",
    number: "6b",
    title: "Miniatures for Flute and Three Strings: Flames",
    date: "February 13, 1950",
    scoring: "Flute, Violin, Viola, Cello",
  },
  {
    id: "418",
    opus: "23",
    number: "7",
    title: "Passacaglia",
    date: "February 22, 1950",
    scoring: "Piano",
  },
  {
    id: "419",
    opus: "23",
    number: "8",
    title: "Chorale Prelude (Brahms Style) on Christ, Der is Mein Leben",
    date: "March 23, 1950",
    scoring: "Piano or Organ",
  },
  {
    id: "420",
    opus: "21",
    number: "7",
    title: "Composition in the Style of Stravinsky",
    date: "March - April 1949",
    scoring: "Orchestra",
  },
  {
    id: "421",
    opus: "24",
    number: "",
    title:
      "Devotion  (For the Brigham Young Statue Unveiling in Washington D.C. June 1st 1950)",
    date: "May 5-20, 1950",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "422",
    opus: "25",
    number: "1",
    title: "Symphony No. 1, “Sierra” (Allegro) 1st Movement",
    date: "November 1949 - April 1950",
    scoring: "Orchestra",
  },
  {
    id: "423",
    opus: "G",
    number: "34",
    title: "Valley Home",
    date: "July 1, 1950",
    scoring: "Male Quartet or Chorus",
  },
  {
    id: "424",
    opus: "C",
    number: "26",
    title: "Prelude (Chopin--Vocal Arrangement -- Text by Jon Beck Shank)",
    date: "October 14, 1950",
    scoring: "Mixed Chorus Accompanied",
  },
  {
    id: "425",
    opus: "C",
    number: "27",
    title: "The Maid With the Flaxen Hair (Debussey Prelude No. 8)",
    date: "October 14, 1950",
    scoring: "Mixed Chorus, Soprano and Harp",
  },
  {
    id: "426",
    opus: "B",
    number: "21",
    title: "You’ll Get to HeavenYet",
    date: "May 1950",
    scoring: "Piano - Voice  Publication",
  },
  {
    id: "427",
    opus: "B",
    number: "22",
    title: "I Wonder Why",
    date: "May 1950",
    scoring: "Piano - Voice  Publication",
  },
  {
    id: "428",
    opus: "B",
    number: "23",
    title: "Sparking on a Sunday Night",
    date: "June 1950",
    scoring: "Piano - Voice  Publication",
  },
  {
    id: "429",
    opus: "B",
    number: "24",
    title: "My Heart is Lost and Lonely",
    date: "June 1950",
    scoring: "Piano - Voice  Publication",
  },
  {
    id: "430",
    opus: "C",
    number: "28",
    title: "Excerpts from Promised Valley",
    date: "",
    scoring: "Mixed Chorus and Organ",
  },
  {
    id: "431",
    opus: "C",
    number: "29",
    title:
      "The Lord Is My Shepherd  (For Sunday School General Board for use in Conference April 1951)",
    date: "January 7, 1951",
    scoring: "Mixed Chorus and Organ",
  },
  {
    id: "432",
    opus: "C",
    number: "30",
    title:
      "O How Lovely Was The Morning (For Sunday School General Board for use in Conference April 1951)",
    date: "January 28, 1951",
    scoring: "Mixed Chorus A Cappella",
  },
  {
    id: "433",
    opus: "C",
    number: "31",
    title:
      "An Angel From on High (For Sunday School General Board for use in Conference April 1951)",
    date: "February 13, 1951",
    scoring: "Mixed Chorus A Cappella",
  },
  {
    id: "434",
    opus: "26",
    number: "1",
    title:
      "Night Song of the Mountains (Written forSalt Lake City Schools program for National Federation of Music Clubs  Convention May 1951)",
    date: "March 18-19, 1951",
    scoring: "Chorus (2 part) Accompanied",
  },
  {
    id: "435",
    opus: "C",
    number: "32",
    title: "Three Blind Mice  (Arranged from Opus C No. 10)",
    date: "May 16, 1951",
    scoring: "Mixed Chorus",
  },
  {
    id: "436",
    opus: "G",
    number: "35",
    title: "Valley Home",
    date: "",
    scoring: "Soprano and Male Chorus and Piano",
  },
  {
    id: "437",
    opus: "20",
    number: "a",
    title: "Choral Movement, Joy (Choral Parts)",
    date: "August 1951",
    scoring: "Vocal Score (Pianos 2)",
  },
  {
    id: "438",
    opus: "20",
    number: "b",
    title: "Choral Movement, Joy (Vocal Score)",
    date: "August 1951",
    scoring: "Piano (2)",
  },
  {
    id: "439",
    opus: "25",
    number: "4",
    title: "Symphony (Corrections and Alterations)",
    date: "December 1951",
    scoring: "Orchestra",
  },
  {
    id: "440",
    opus: "C",
    number: "33",
    title:
      "O Lord, Do Not Despise Me (Bach Cantata No. 135)  Translation by Gerrit deJong Verses 1 and 6)",
    date: "January 1952",
    scoring: "Chorus",
  },
  {
    id: "441",
    opus: "C",
    number: "34",
    title: "Annie Laurie (MIA June Conference 1952)",
    date: "February 21-25, 1952",
    scoring: "Chorus",
  },
  {
    id: "442",
    opus: "A",
    number: "3",
    title: "Reverie (Mother No.3 of Enfantines by Earnest Bloch",
    date: "March 28, 1952",
    scoring: "Transcription for Piano",
  },
  {
    id: "443",
    opus: "26",
    number: "2",
    title:
      "In Triumph We Shall Sing (MIA Rally Song)  words by Ruth May Fox) MIA June Conference 1952",
    date: "April 30, 1952",
    scoring: "Chorus",
  },
  {
    id: "444",
    opus: "E",
    number: "19",
    title: "Rhapsody for Piano and Orchestra (From Opus 2 No. 12)",
    date: "1938",
    scoring: "Concerto",
  },
  {
    id: "445",
    opus: "C",
    number: "35",
    title: "Marie, Farewell to Thee",
    date: "May 1941",
    scoring: "Mixed Quintet",
  },
  {
    id: "446",
    opus: "C",
    number: "36",
    title: "Sweeter Than the Sweetest (From Modernaires Recording)",
    date: "December 1941",
    scoring: "Mixed Quintet",
  },
  {
    id: "447",
    opus: "C",
    number: "37",
    title: "Le Cygne  Text by Jon Beck Shank)",
    date: "August 13-14, 1952",
    scoring: "Chorus and Strings",
  },
  {
    id: "448",
    opus: "13",
    number: "2",
    title: "Gavotte",
    date: "1943",
    scoring: "Piano",
  },
  {
    id: "449",
    opus: "F",
    number: "23",
    title: "O Come All Ye Faithful",
    date: "December 2, 1952",
    scoring: "Female Chorus",
  },
  {
    id: "450",
    opus: "C",
    number: "38",
    title: "Sleeper’s Awake (Bach - Cantata No. 140)",
    date: "January 1953",
    scoring: "Chorus",
  },
  {
    id: "451",
    opus: "F",
    number: "24",
    title: "Be Thou An Example of the Believers (Timothy)",
    date: "January 29, 1953",
    scoring: "Female Chorus",
  },
  {
    id: "452",
    opus: "O",
    number: "2",
    title: "Interlude From Promised Valley",
    date: "February 21, 23, 1953",
    scoring: "Orchestra Arrangement",
  },
  {
    id: "453",
    opus: "C",
    number: "39",
    title: "BYU College Song",
    date: "March 9, 1953",
    scoring: "Chorus",
  },
  {
    id: "454",
    opus: "27",
    number: "1",
    title: "Suite on Latter-day Saint Hymns - No. 1 (Come Come Ye Saints)",
    date: "March - April 8, 1953",
    scoring: "Orchestra",
  },
  {
    id: "455",
    opus: "27",
    number: "2",
    title: "Suite on Latter-day Saint Hymns - No. 2 (Sweet is the Work)",
    date: "March - April 8, 1953",
    scoring: "Orchestra",
  },
  {
    id: "456",
    opus: "27",
    number: "3",
    title: "Suite on Latter-day Saint Hymns - No. 3 (High On a Mountain Top)",
    date: "March - April 8, 1953",
    scoring: "Orchestra",
  },
  {
    id: "457",
    opus: "O",
    number: "3",
    title: "We Two (By Clyde Sandgren)",
    date: "April 24, 1953 - May 6-7, 1953",
    scoring: "Orchestra",
  },
  {
    id: "458",
    opus: "C",
    number: "40",
    title: "Medley of MIA Songs",
    date: "April 24, 1953",
    scoring: "Chorus",
  },
  {
    id: "459",
    opus: "O",
    number: "4",
    title: "Carry On (by Alfred Durham)",
    date: "May 8, 1953",
    scoring: "Orchestra",
  },
  {
    id: "460",
    opus: "26",
    number: "3",
    title: "Organ Prelude to Onward Christian Soldiers",
    date: "May 22, 1953",
    scoring: "Organ",
  },
  {
    id: "461",
    opus: "26",
    number: "4",
    title: "Fanfare for Three Trumpets",
    date: "May 27, 1953",
    scoring: "Trumpet Trio",
  },
  {
    id: "462",
    opus: "E",
    number: "20a",
    title:
      "Trumpet Music for the Cumorah Pageant - Fanfare (for four trumpets)",
    date: "",
    scoring: "Trumpet Quartet",
  },
  {
    id: "463",
    opus: "E",
    number: "20b",
    title:
      "Trumpet Music for the Cumorah Pageant - for four trumpets:  High on a Mountain Top",
    date: "",
    scoring: "Trumpet Quartet",
  },
  {
    id: "464",
    opus: "E",
    number: "21",
    title: "Trumpet Music for the Cumorah Pageant - An Angel from On High",
    date: "",
    scoring: "Trumpet Quartet",
  },
  {
    id: "465",
    opus: "25",
    number: "2",
    title: "Lento Romanza (Symphony No. 1) - 2nd Movement",
    date: "Summer 1953",
    scoring: "Orchestra",
  },
  {
    id: "466",
    opus: "25",
    number: "3",
    title: "Symphony No. 1 - Animato Vivo - 3rd Movement",
    date: "July 1953 - November 2, 1954",
    scoring: "Orchestra",
  },
  {
    id: "467",
    opus: "O",
    number: "5",
    title:
      "Now Let All the Heaven’s Adore Thee (From Cantata No. 140 “ Sleepers Awake!”)",
    date: "December 28, 1955",
    scoring: "Orchestra",
  },
  {
    id: "468",
    opus: "G",
    number: "36",
    title: "Star Spangled Banner",
    date: "January 23, 1954",
    scoring: "Male Chorus",
  },
  {
    id: "469",
    opus: "26",
    number: "5",
    title: "Beehive Flight Song",
    date: "January 23-25, 1954",
    scoring: "Female Chorus",
  },
  {
    id: "470",
    opus: "C",
    number: "41",
    title: "The Spirit of God Like a Fire is Burning",
    date: "February 13, 20, 1954  Completed March 20, 1954",
    scoring: "Mixed Chorus and Piano",
  },
  {
    id: "471",
    opus: "28",
    number: "",
    title: "Come Back My Son",
    date: "Started February 25, Completed 1954",
    scoring: "Film Score",
  },
  {
    id: "472",
    opus: "A",
    number: "4",
    title: "If I Loved You (Carousel - Rodgers)",
    date: "June 5, 1954",
    scoring: "Solo Instruments",
  },
  {
    id: "473",
    opus: "A",
    number: "5",
    title: "Whistle a Happy Tune (King and I - Rodgers)",
    date: "June 6, 1954",
    scoring: "Solo Instruments",
  },
  {
    id: "474",
    opus: "C",
    number: "42",
    title: "Salute to Omaha",
    date: "July 27-28, 1954",
    scoring: "Mixed Chorus, Organ, and Trumpets (3)",
  },
  {
    id: "475",
    opus: "F",
    number: "25",
    title: "O How Lovely Was The Morning",
    date: "August 14, 1954",
    scoring: "Female Chorus",
  },
  {
    id: "476",
    opus: "E",
    number: "22",
    title: "Hora Breve",
    date: "January - February 1953",
    scoring: "Piano (2) / Strings",
  },
  {
    id: "477",
    opus: "O",
    number: "6",
    title: "Break Forth O Beauteous Heav’nly Light",
    date: "December 31, 1954",
    scoring: "Orchestra",
  },
  {
    id: "478",
    opus: "O",
    number: "7",
    title: "Excerpts from Promised Valley",
    date: "January 1955",
    scoring: "Orchestra",
  },
  {
    id: "479",
    opus: "C",
    number: "43",
    title: "Londonderry Aire (No. 2)",
    date: "December 1954 - January 1955",
    scoring: "Chorus",
  },
  {
    id: "480",
    opus: "O",
    number: "8",
    title: "Oh Captain, My Captain! (After Walt Whitman)",
    date: "February 8-20, 1955",
    scoring: "Orchestra",
  },
  {
    id: "481",
    opus: "30",
    number: "",
    title: "The Happy City",
    date: "January 22 - March 1, 1955",
    scoring: "Film Score",
  },
  {
    id: "482",
    opus: "C",
    number: "44",
    title: "Abide With Me, ‘Tis Eventide!",
    date: "March 14, 1955",
    scoring: "Chorus",
  },
  {
    id: "483",
    opus: "G",
    number: "37",
    title: "O How Lovely Was The Morning",
    date: "March 16, 1955",
    scoring: "Male Chorus",
  },
  {
    id: "484",
    opus: "C",
    number: "45",
    title: "O Jesus, Savior, Refuge Mine",
    date: "March 25, 1955",
    scoring: "Organ, Brass and Mixed Chorus",
  },
  {
    id: "485",
    opus: "O",
    number: "9",
    title: "Presentation of the MIA Symphony Orchestra",
    date: "April 1955 (Maybe May)",
    scoring: "Orchestra",
  },
  {
    id: "486",
    opus: "C",
    number: "46",
    title: "Let Us Oft Speak Kind Words",
    date: "July 28-29, 1955",
    scoring: "Chorus",
  },
  {
    id: "487",
    opus: "31",
    number: "1",
    title: "Pastorale",
    date: "January 1955",
    scoring: "Orchestra",
  },
  {
    id: "488",
    opus: "31",
    number: "2",
    title: "Oh My Luve’s Like a Red, Red Rose",
    date: "September 14, 1955",
    scoring: "A capella",
  },
  {
    id: "489",
    opus: "O",
    number: "10",
    title: "Happy Holiday Medley",
    date: "November 4-12, 1955",
    scoring: "Orchestra",
  },
  {
    id: "490",
    opus: "O",
    number: "11",
    title: "It’s the Ward Teachers",
    date: "December 3-9, 1955",
    scoring: "Film Score",
  },
  {
    id: "491",
    opus: "31",
    number: "3",
    title: "Organ Interludes",
    date: "January 7, 1956",
    scoring: "Organ",
  },
  {
    id: "492",
    opus: "32",
    number: "",
    title: "An Overture to Spring",
    date: "March 1956",
    scoring: "Orchestra",
  },
  {
    id: "493",
    opus: "C",
    number: "47",
    title: "Waltz of the Flowers",
    date: "February 18-19, 1956",
    scoring: "Chorus",
  },
  {
    id: "494",
    opus: "O",
    number: "12",
    title: "How Near to the Angels",
    date: "May 23 -25, 1956",
    scoring: "Film Score",
  },
  {
    id: "495",
    opus: "E",
    number: "23",
    title: "The Wind is a Lion",
    date: "June 1, 1956",
    scoring: "Violins (5) and piano",
  },
  {
    id: "496",
    opus: "A",
    number: "6",
    title: "MIA Class Song Skit Music",
    date: "June 4, 1956",
    scoring: "Solo Instruments",
  },
  {
    id: "497",
    opus: "C",
    number: "48",
    title: "Sleep",
    date: "October 29, 1956",
    scoring: "Chorus",
  },
  {
    id: "498",
    opus: "O",
    number: "13",
    title: "Christmas In the Air (After Walter Schumman Album)",
    date: "Started November 10, 1956",
    scoring: "Orchestra",
  },
  {
    id: "499",
    opus: "29",
    number: "",
    title: "Symphony No. 2 (Music for the Hill Cumorah Pageant)",
    date: "Started 1953 - Complete March 20, 1957",
    scoring: "Orchestra and Chorus",
  },
  {
    id: "500",
    opus: "C",
    number: "49",
    title: "Valley Home (from Promised Valley)",
    date: "March 28, 1957",
    scoring: "Male Chorus (unison) and Female Chorus (SSA)",
  },
  {
    id: "501",
    opus: "O",
    number: "14",
    title: "Feed My Sheep",
    date: "Started May 10, 1957 - Completed May 18, 1957",
    scoring: "Film Score",
  },
  {
    id: "502",
    opus: "O",
    number: "15",
    title: "The Fruitful Years",
    date: "May 1957",
    scoring: "Orchestra",
  },
  {
    id: "503",
    opus: "G",
    number: "38",
    title: "The Fruitful Years",
    date: "May 1957",
    scoring: "Male Chorus",
  },
  {
    id: "504",
    opus: "G",
    number: "39",
    title: "The Wind Is a Lion",
    date: "October 4-9, 1957",
    scoring: "Male Chorus",
  },
  {
    id: "505",
    opus: "C",
    number: "50",
    title: "Otto Harbach Medley",
    date: "October 10-15, 1957",
    scoring: "Chorus",
  },
  {
    id: "506",
    opus: "O",
    number: "16",
    title: "Let’s Dream Together",
    date: "October 15-17, 1957",
    scoring: "Orchestra",
  },
  {
    id: "507",
    opus: "34",
    number: "",
    title: "Original Music for “Praise Ye The Lord!”",
    date: "January - May 1958",
    scoring: "Orchestra, Chorus and Dance",
  },
  {
    id: "508",
    opus: "34",
    number: "a",
    title: "Music for Timbrel and Dance",
    date: "",
    scoring: "",
  },
  {
    id: "509",
    opus: "34",
    number: "b",
    title: "The Psalmast's Command",
    date: "",
    scoring: "",
  },
  {
    id: "510",
    opus: "34",
    number: "c",
    title: "Song of the Pre-existance",
    date: "",
    scoring: "",
  },
  {
    id: "511",
    opus: "34",
    number: "d",
    title: "Song of Moses",
    date: "",
    scoring: "",
  },
  {
    id: "512",
    opus: "34",
    number: "e",
    title: "Cortege of the Priests of Solomon",
    date: "",
    scoring: "",
  },
  {
    id: "513",
    opus: "34",
    number: "f",
    title: "Song of Dedication",
    date: "",
    scoring: "",
  },
  {
    id: "514",
    opus: "34",
    number: "g",
    title: "Arrangement of An Angel from High for Scene 1",
    date: "",
    scoring: "",
  },
  {
    id: "515",
    opus: "34",
    number: "h",
    title: "Arrangement of The Lord is my Shephard for Scene 6",
    date: "",
    scoring: "",
  },
  {
    id: "516",
    opus: "C",
    number: "51",
    title: "The Lord Is My Shepherd",
    date: "February 1, 1958",
    scoring: "Chorus",
  },
  {
    id: "517",
    opus: "O",
    number: "17",
    title: "O My Father",
    date: "August - September 1958",
    scoring: "Orchestra",
  },
  {
    id: "518",
    opus: "O",
    number: "18",
    title: "Come Ye Blessed",
    date: "November 1958",
    scoring: "Film Score",
  },
  {
    id: "519",
    opus: "33",
    number: "",
    title: "Sand in Their Shoes",
    date: "Started November 1957 - Completed May 1959",
    scoring: "Musical Play",
  },
  {
    id: "520",
    opus: "35",
    number: "1",
    title: "My Old Country Joan",
    date: "September 7, 1959",
    scoring: "Voice and Harpsichord",
  },
  {
    id: "521",
    opus: "35",
    number: "2",
    title: "18th Century Minuet",
    date: "September 8, 1959",
    scoring: "Violin / Harpsichord",
  },
  {
    id: "522",
    opus: "O",
    number: "19",
    title: "Memories",
    date: "October 13-15, 1959",
    scoring: "Mixed Chorus and Dance Band",
  },
  {
    id: "523",
    opus: "D",
    number: "34",
    title: "Bert Taylor-Band Box-Theme Music",
    date: "November 14-16, 1959",
    scoring: "Dance Band",
  },
  {
    id: "524",
    opus: "36",
    number: "1",
    title: "The New Zion",
    date: "December 5, 1959",
    scoring: "Chorus",
  },
  {
    id: "525",
    opus: "C",
    number: "52",
    title: "America, The Beautiful",
    date: "January 9, 1960",
    scoring: "Mixed Chorus and Piano (4 hands)",
  },
  {
    id: "526",
    opus: "G",
    number: "40",
    title: "O My Father",
    date: "February 6-7, 1960",
    scoring: "Male Chorus",
  },
  {
    id: "527",
    opus: "36",
    number: "2",
    title: "Listen, Dear Teacher",
    date: "February 17, 1960",
    scoring: "Song",
  },
  {
    id: "528",
    opus: "F",
    number: "26",
    title: "I Am a Child of God",
    date: "March 14, 1960",
    scoring: "Women’s Chorus",
  },
  {
    id: "529",
    opus: "B",
    number: "26",
    title: "O My Father",
    date: "March 27, 1960",
    scoring: "Solo Arrangement",
  },
  {
    id: "530",
    opus: "E",
    number: "24",
    title: "This is My Father’s World",
    date: "May 22, 1960",
    scoring: "Instrumental Ensemble",
  },
  {
    id: "531",
    opus: "E",
    number: "24a",
    title: "One World",
    date: "",
    scoring: "",
  },
  {
    id: "532",
    opus: "E",
    number: "24b",
    title: "March of the Men of Harlech",
    date: "",
    scoring: "",
  },
  {
    id: "533",
    opus: "E",
    number: "24c",
    title: "High On The Mountain Top",
    date: "",
    scoring: "",
  },
  {
    id: "534",
    opus: "36",
    number: "3",
    title: "Processional",
    date: "June 4-5, 1960",
    scoring: "Orchestra",
  },
  {
    id: "535",
    opus: "E",
    number: "25",
    title: "Excerpts from Promised Valley",
    date: "July 1960",
    scoring: "Band-Chorus",
  },
  {
    id: "536",
    opus: "36",
    number: "4",
    title: "Bonneville High School Hymn",
    date: "",
    scoring: "Hymn (School Hymn)",
  },
  {
    id: "537",
    opus: "D",
    number: "35",
    title: "West Side Story - Excerpts",
    date: "",
    scoring: "Band",
  },
  {
    id: "538",
    opus: "37",
    number: "",
    title: "Papa and the Playhouse",
    date: "1962",
    scoring: "Musical Play",
  },
  {
    id: "539",
    opus: "O",
    number: "20",
    title: "The Windows of Heaven",
    date: "November 1962",
    scoring: "Film Score",
  },
  {
    id: "540",
    opus: "G",
    number: "40",
    title: "I Dream of Jeanie",
    date: "February 16 and May 4, 1963",
    scoring: "Male Chorus (Unison) and Piano",
  },
  {
    id: "541",
    opus: "O",
    number: "21",
    title: "I Dream of Jeanie",
    date: "May 25, 1963",
    scoring: "Male Chorus and Orchestra",
  },
  {
    id: "542",
    opus: "38",
    number: "",
    title: "Symphony No. 3",
    date: "Started Summer 1962",
    scoring: "Orchestra",
  },
  {
    id: "543",
    opus: "39",
    number: "1",
    title: "Spires of Manti",
    date: "July 1, 1963",
    scoring: "Choir",
  },
  {
    id: "544",
    opus: "O",
    number: "22",
    title: "Clair de Lune",
    date: "February 15, 17-18, 1964",
    scoring: "Orchestra",
  },
  {
    id: "545",
    opus: "O",
    number: "23",
    title: "Man’s Search for Happiness",
    date: "February 27 - March 6, 1964",
    scoring: "Film Score",
  },
  {
    id: "546",
    opus: "38a",
    number: "",
    title: "Symphony No. 3 (Two Piano Reduction)",
    date: "March 29-31, 1964",
    scoring: "Piano (Piano (2) Reduction",
  },
  {
    id: "547",
    opus: "39",
    number: "2",
    title: "Herald and Hymn for Brasses",
    date: "April 1964",
    scoring: "Brass",
  },
  {
    id: "548",
    opus: "39",
    number: "3",
    title: "Three Songs for the Young Heart",
    date: "April 1964",
    scoring: "Voice (Youth) and Piano",
  },
  {
    id: "549",
    opus: "",
    number: "",
    title: "a.  For I am Called by Thy Name",
    date: "April 1964",
    scoring: "(For Adolescent Voice and Piano)",
  },
  {
    id: "550",
    opus: "",
    number: "",
    title: "b.   A New Commandment",
    date: "April 1964",
    scoring: "(For Adolescent Voice and Piano)",
  },
  {
    id: "551",
    opus: "",
    number: "",
    title: "c,  To Obey is Better than Sacrifice",
    date: "April 1964",
    scoring: "(For Adolescent Voice and Piano)",
  },
  {
    id: "552",
    opus: "K",
    number: "1",
    title: "Orchestral Settings of Beloved Mormon Hymns: Sweet is the Work",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "553",
    opus: "K",
    number: "2",
    title: "Orchestral Settings of Beloved Mormon Hymns: O My Father",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "554",
    opus: "K",
    number: "3",
    title: "Orchestral Settings of Beloved Mormon Hymns: As the Dew",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "555",
    opus: "K",
    number: "4",
    title:
      "Orchestral Settings of Beloved Mormon Hymns: Abide With Me Tis Eventide",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "556",
    opus: "K",
    number: "5",
    title: "Orchestral Settings of Beloved Mormon Hymns: How Great the Wisdom",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "557",
    opus: "K",
    number: "6",
    title: "Orchestral Settings of Beloved Mormon Hymns: Thou Deep’ning Trials",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "558",
    opus: "K",
    number: "7",
    title:
      "Orchestral Settings of Beloved Mormon Hymns: The Lord Is My Shepherd",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "559",
    opus: "K",
    number: "8",
    title: "Orchestral Settings of Beloved Mormon Hymns: Now the Day is Over",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "560",
    opus: "K",
    number: "9",
    title:
      "Orchestral Settings of Beloved Mormon Hymns: I Need Thee Every Hour",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "561",
    opus: "K",
    number: "10",
    title: "Orchestral Settings of Beloved Mormon Hymns: Abide With Me",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "562",
    opus: "K",
    number: "11",
    title: "Orchestral Settings of Beloved Mormon Hymns: Redeemer of Israel",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "563",
    opus: "K",
    number: "12",
    title: "Orchestral Settings of Beloved Mormon Hymns: O Ye Mountains High",
    date: "June 22 - July 22, 1964",
    scoring: "Chamber Orchestra",
  },
  {
    id: "564",
    opus: "40",
    number: "",
    title: "Portrait of a Great Leader",
    date: "August 10 - September 30, 1964",
    scoring: "Orchestra",
  },
  {
    id: "565",
    opus: "D",
    number: "36",
    title: "Mister Million",
    date: "August 1964",
    scoring: "Dance Band and Male Quartet",
  },
  {
    id: "566",
    opus: "C",
    number: "53",
    title: "Title Unknown",
    date: "March 1965",
    scoring: "Mixed Quartet",
  },
  {
    id: "567",
    opus: "C",
    number: "54",
    title: "Tell Me Why",
    date: "April 1965",
    scoring: "Mixed Quartet",
  },
  {
    id: "568",
    opus: "F",
    number: "27",
    title: "I Need Thee Every Hour",
    date: "April 1965",
    scoring: "Female Quartet",
  },
  {
    id: "569",
    opus: "41",
    number: "",
    title: "And Should We Die",
    date: "November - December 1965",
    scoring: "Film Music",
  },
  {
    id: "570",
    opus: "42",
    number: "1",
    title: "Miniature for Horn Octet",
    date: "November 1966",
    scoring: "Brass (Horn Octet)",
  },
  {
    id: "571",
    opus: "43",
    number: "1",
    title: "Brass Quartet",
    date: "March 1, 1967",
    scoring: "Brass Quartet",
  },
  {
    id: "572",
    opus: "43",
    number: "2",
    title: "Andantino for Brass Quartet",
    date: "April 1973",
    scoring: "Brass Quartet",
  },
  {
    id: "573",
    opus: "43",
    number: "3",
    title: "Scherzo",
    date: "April 1973",
    scoring: "Brass Quartet",
  },
  {
    id: "574",
    opus: "42",
    number: "2",
    title: "Palm Sunday - Call to Worship",
    date: "March 15, 1967",
    scoring: "Brass (3 trumpets and 3 trombones)",
  },
  {
    id: "575",
    opus: "44",
    number: "",
    title: "Rendezvous!",
    date: "July 1967",
    scoring: "Musical Play",
  },
  {
    id: "576",
    opus: "45",
    number: "",
    title: "Primary Conference Materials",
    date: "2/10-18/1968",
    scoring: "2-Part Children’s Chorus, Harp, and Organ",
  },
  {
    id: "577",
    opus: "45",
    number: "1",
    title:
      "Primary Conference Materials, No. 1, “Three Interludes for Three Harps”",
    date: "February 11, 1968",
    scoring: "2-Part Children’s Chorus, Harp, and Organ",
  },
  {
    id: "578",
    opus: "45",
    number: "2",
    title: "Primary Conference Materials, No. 2, “Teacher Touch My Heart”",
    date: "February 11, 1968",
    scoring: "2-Part Children’s Chorus, Harp, and Organ",
  },
  {
    id: "579",
    opus: "45",
    number: "3",
    title: "Primary Conference Materials, No. 3, “Tithing”",
    date: "February 11, 1968",
    scoring: "2-Part Children’s Chorus, Harp, and Organ",
  },
  {
    id: "580",
    opus: "45",
    number: "4",
    title: "Primary Conference Materials, No. 4, “Baptism”",
    date: "February 11, 1968",
    scoring: "2-Part Children’s Chorus, Harp, and Organ",
  },
  {
    id: "581",
    opus: "45",
    number: "5",
    title:
      "Primary Conference Materials, No. 5, “My Heavenly Father Wants Me to Be Happy”",
    date: "February 18, 1968",
    scoring: "2-Part Children’s Chorus, Harp, and Organ",
  },
  {
    id: "582",
    opus: "45",
    number: "6",
    title: "Primary Conference Materials, No. 6, “Christmas Song”",
    date: "February 18, 1968",
    scoring: "2-Part Children’s Chorus, Harp, and Organ",
  },
  {
    id: "583",
    opus: "46",
    number: "",
    title: "Trumpet Concertino",
    date: "October 17, 1968",
    scoring: "Trumpet and Chamber Orchestra",
  },
  {
    id: "584",
    opus: "O",
    number: "24",
    title: "Chorale Prelude (Wachet-Auf by Bach)",
    date: "October 22, 1968",
    scoring: "Orchestra",
  },
  {
    id: "585",
    opus: "E",
    number: "26",
    title: "Brass Chorale",
    date: "October 23, 1968",
    scoring: "Brass",
  },
  {
    id: "586",
    opus: "O",
    number: "25",
    title: "Ave Maria",
    date: "December 1968",
    scoring: "Orchestra",
  },
  {
    id: "587",
    opus: "B",
    number: "27",
    title: "Clyde Sandgren Song No. 1 - In Valley of Home",
    date: "February 28, 1970",
    scoring: "Voice and Piano",
  },
  {
    id: "588",
    opus: "B",
    number: "28",
    title: "Clyde Sandgren Song No. 2 - When I Touch",
    date: "March 2, 1970",
    scoring: "Voice and Piano",
  },
  {
    id: "589",
    opus: "B",
    number: "29",
    title: "Clyde Sandgren Song No. 3 - April of Our Seasons",
    date: "March 3, 1970",
    scoring: "Voice and Piano",
  },
  {
    id: "590",
    opus: "B",
    number: "30",
    title: "Clyde Sandgren Song No. 4 - One Day a Boy",
    date: "March 3, 1970",
    scoring: "Voice and Piano",
  },
  {
    id: "591",
    opus: "B",
    number: "31",
    title:
      "Clyde Sandgren Song No. 5 - What’s the Fourth of July Without a Parade",
    date: "May 1970",
    scoring: "Voice and Piano",
  },
  {
    id: "592",
    opus: "42",
    number: "3",
    title: "Rockford Festival Fanfare No. 1",
    date: "July 1, 1970",
    scoring: "Brass Choir and Percussion",
  },
  {
    id: "593",
    opus: "42",
    number: "4",
    title: "Rockford Festival Fanfare No. 2",
    date: "July 8, 1970",
    scoring: "Brass Choir Only",
  },
  {
    id: "594",
    opus: "B",
    number: "32",
    title:
      "Clyde Sandgren Song No. 6 - You Should See the Meadows in the Morning",
    date: "July 11, 1970",
    scoring: "Solo Voice",
  },
  {
    id: "595",
    opus: "45",
    number: "7",
    title: "Joy",
    date: "January 23, 1971",
    scoring: "Female Chorus (SSAA) and Piano (4 hands)",
  },
  {
    id: "596",
    opus: "B",
    number: "33",
    title: "Clyde Sandgren Song No. 7 - Little Girl",
    date: "April 28, 1971",
    scoring: "Voice and Piano",
  },
  {
    id: "597",
    opus: "B",
    number: "34",
    title: "Clyde Sandgren Song No. 8 - Pretending",
    date: "April 28, 1971",
    scoring: "Voice and Piano",
  },
  {
    id: "598",
    opus: "B",
    number: "35",
    title: "Clyde Sandgren Song No. 9 - This Is My Land",
    date: "April 28, 1971",
    scoring: "Voice and Piano",
  },
  {
    id: "599",
    opus: "B",
    number: "36",
    title: "Clyde Sandgren Song No. 10 - Never Again",
    date: "April 28, 1971",
    scoring: "Voice and Piano",
  },
  {
    id: "600",
    opus: "45",
    number: "8",
    title: "Joy",
    date: "December 3, 1971",
    scoring: "SSAA and Orchestra",
  },
  {
    id: "601",
    opus: "19b",
    number: "",
    title: "Promised Valley",
    date: "April 26 - May 26, 1972",
    scoring: "Orchestra",
  },
  {
    id: "602",
    opus: "19b",
    number: "2a",
    title: "1972 Revised Prologue to Promised Valley",
    date: "1972",
    scoring: "Orchestra",
  },
  {
    id: "603",
    opus: "47",
    number: "1",
    title: "Salt Lake Tabernacle Christmas Pageant No. 1, “A Scene of Glory”",
    date: "September - November 1972",
    scoring: "Chorus, Organ, Orchestra",
  },
  {
    id: "604",
    opus: "47",
    number: "2",
    title:
      "Salt Lake Tabernacle Christmas Pageant No. 2, “Psalm of the Savior”",
    date: "September - November 1972",
    scoring: "Chorus, Organ, Orchestra",
  },
  {
    id: "605",
    opus: "47",
    number: "3",
    title: "He Is Not Here",
    date: "September-November 1972",
    scoring: "Chorus, Organ, Orchestra",
  },
  {
    id: "606",
    opus: "B",
    number: "37",
    title: "Here By My Window",
    date: "December 21, 1973",
    scoring: "Voice and Piano",
  },
  {
    id: "607",
    opus: "48",
    number: "",
    title: "Sonata for Horn and Piano",
    date: "May 1 - June 7, 1974",
    scoring: "Horn and Piano",
  },
  {
    id: "608",
    opus: "",
    number: "",
    title: "Movement #1 Prologue and Toccata",
    date: "",
    scoring: "",
  },
  {
    id: "609",
    opus: "",
    number: "",
    title: "Movement #2 Romanza",
    date: "",
    scoring: "",
  },
  {
    id: "610",
    opus: "",
    number: "",
    title: "Movemnt #3 Roundo and Epilogue",
    date: "",
    scoring: "",
  },
  {
    id: "611",
    opus: "49",
    number: "1",
    title: "For I Am Called By Thy Name",
    date: "October 31, 1974",
    scoring: "Chorus (SATB) and Piano (or Organ)",
  },
  {
    id: "612",
    opus: "49",
    number: "2",
    title: "A New Commandment I Give Unto You",
    date: "November 8, 1974",
    scoring: "Chorus (SATB) and Piano (or Organ)",
  },
  {
    id: "613",
    opus: "49",
    number: "3",
    title: "To Obey is Better Than Sacrifice",
    date: "November 8, 1974",
    scoring: "Chorus (SATB) and Piano (or Organ)",
  },
  {
    id: "614",
    opus: "L",
    number: "1",
    title: "Intro and Coda to “It’s a Small Small World”",
    date: "November 13, 1974",
    scoring: "Orchestra",
  },
  {
    id: "615",
    opus: "L",
    number: "2",
    title: "Interlude",
    date: "November 16, 1974",
    scoring: "Piano",
  },
  {
    id: "616",
    opus: "49",
    number: "4a-b",
    title: "A Melody for Betsy",
    date: "August 1974",
    scoring: "Piano Solo or Voice and Piano",
  },
  {
    id: "617",
    opus: "49",
    number: "5",
    title: "Scherzino",
    date: "December 30, 1974",
    scoring: "Piano",
  },
  {
    id: "618",
    opus: "50",
    number: "",
    title: "Symphony No. 4, “A New Morning”",
    date: "January 6, 1975",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "619",
    opus: "51",
    number: "",
    title: "Ballad of the Prairie State",
    date: "February 1975",
    scoring: "Orchestra",
  },
  {
    id: "620",
    opus: "F",
    number: "28",
    title: "Some Children See Him",
    date: "January 1975",
    scoring: "Female Chorus (SA) and Cello obbligato",
  },
  {
    id: "621",
    opus: "L",
    number: "3",
    title: "Baptism",
    date: "January 1975",
    scoring: "Cello obbligato",
  },
  {
    id: "622",
    opus: "L",
    number: "4",
    title: "Wizard of Oz, “Courage”",
    date: "?",
    scoring: "Piano",
  },
  {
    id: "623",
    opus: "49",
    number: "6",
    title: "Our Savior’s Love",
    date: "February 8, 1976",
    scoring: "Hymn",
  },
  {
    id: "624",
    opus: "49",
    number: "7",
    title: "What Is Truth",
    date: "March 7, 1976",
    scoring: "Hymn",
  },
  {
    id: "625",
    opus: "L",
    number: "5",
    title: "Territory’s Great",
    date: "March - April 1976",
    scoring: "Male Chorus and Stage Band",
  },
  {
    id: "626",
    opus: "52",
    number: "",
    title: "Wisconsin Profiles",
    date: "June 7, 1976",
    scoring: "Orchestra",
  },
  {
    id: "627",
    opus: "L",
    number: "6",
    title: "Insert - Do Re Mi (from the Sound of Music)",
    date: "November 1976",
    scoring: "Orchestra",
  },
  {
    id: "628",
    opus: "L",
    number: "7",
    title: "Insert - Do Re Mi (from the Sound of Music)",
    date: "November 1976",
    scoring: "Orchestra",
  },
  {
    id: "629",
    opus: "C",
    number: "55",
    title: "Christmas in the Heart",
    date: "December 5, 1976",
    scoring: "Mixed Chorus",
  },
  {
    id: "630",
    opus: "53",
    number: "",
    title: "Suite for Tuba",
    date: "April 28, 1977",
    scoring: "Tuba, Harp, Celesta, Piano, and Percussion",
  },
  {
    id: "631",
    opus: "O",
    number: "26",
    title: "Ascot Gavotte",
    date: "May 12, 1977",
    scoring: "Orchestra and Symphony Singers",
  },
  {
    id: "632",
    opus: "54",
    number: "",
    title: "Sails, Winds & Echoes",
    date: "January 1978",
    scoring: "Flute Choir",
  },
  {
    id: "633",
    opus: "55",
    number: "",
    title: "Symphony No. 5  PERELANDRA",
    date: "1978 - 1979",
    scoring: "Orchestra",
  },
  {
    id: "634",
    opus: "L",
    number: "8",
    title: "New York New York",
    date: "January 1978",
    scoring: "Orchestra",
  },
  {
    id: "635",
    opus: "O",
    number: "27",
    title:
      "Concerto for Trombone and Orchestra derived from Sonata No. 5 by Galliard for Bassoon or Cello",
    date: "18 March 1978",
    scoring: "Concerto for Trombone and Orchestra",
  },
  {
    id: "636",
    opus: "O",
    number: "28",
    title: "Yankee-Doodle Sing-a-Long",
    date: "",
    scoring: "Orchestra",
  },
  {
    id: "637",
    opus: "L",
    number: "9",
    title: "Edelweiss (from The Sound of Music by Richard Rogers)",
    date: "24 March 1978",
    scoring: "Orchestra",
  },
  {
    id: "638",
    opus: "L",
    number: "10",
    title: "Why Do I Love You (From Showboat by Jerome Kern)",
    date: "25 March 1978",
    scoring: "Orchestra",
  },
  {
    id: "639",
    opus: "L",
    number: "11",
    title: "Some Enchanted Evening (from South Pacific by Richard Rodgers)",
    date: "25 March 1978",
    scoring: "Orchestra",
  },
  {
    id: "640",
    opus: "L",
    number: "12",
    title: "If Ever I Would Leave You (from Camelot) Loewe",
    date: "27 March 1978",
    scoring: "Orchestra",
  },
  {
    id: "641",
    opus: "O",
    number: "29",
    title: "Old Man River (from Showboat)",
    date: "29 March 1978",
    scoring: "Orchestra",
  },
  {
    id: "642",
    opus: "56",
    number: "",
    title: "Sonata for Percussion Trio",
    date: "22 December 1978 / Completed January 1-2, 1979",
    scoring: "Percussion",
  },
  {
    id: "643",
    opus: "F",
    number: "28",
    title: "Sweet Is The Work",
    date: "April 1979",
    scoring: "Female Chorus",
  },
  {
    id: "644",
    opus: "L",
    number: "13",
    title: "Get Me to the Church (from My Fair Lady)",
    date: "May 1979",
    scoring: "Orchestra",
  },
  {
    id: "645",
    opus: "47",
    number: "3a",
    title: "He Is Not Here (An Anthem for Easter)",
    date: "July 4 1979",
    scoring: "Chorus (SATB)",
  },
  {
    id: "646",
    opus: "57",
    number: "1",
    title: "Ring Out, Wild Bells!",
    date: "18 July 1979",
    scoring: "Mixed Chorus, Organ or Piano",
  },
  {
    id: "647",
    opus: "E",
    number: "27",
    title: "Profane Procession and Dance",
    date: "26-29 October 1979",
    scoring: "Band",
  },
  {
    id: "648",
    opus: "58",
    number: "1",
    title: "Prologue and Prophecy",
    date: "January 10 - April 5, 1980",
    scoring: "Mixed Chorus, Baritone Solo, Brass Quintet and Percussion.",
  },
  {
    id: "649",
    opus: "58",
    number: "2",
    title: "Prologue and Prophecy",
    date: "January 10 - April 5, 1980",
    scoring: "Mixed Chorus, Baritone Solo, Brass Quintet and Percussion.",
  },
  {
    id: "650",
    opus: "O",
    number: "30",
    title: "In A Special Way (Orchestration for a song by Dick Clark)",
    date: "6 May 1980",
    scoring: "Orchestra",
  },
  {
    id: "651",
    opus: "O",
    number: "31",
    title: "Alleluia - Jack Sweeney",
    date: "10 July 1980",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "652",
    opus: "L",
    number: "14",
    title: "Ending To Old Man River",
    date: "Early 70’s",
    scoring: "Symphony Orchestra",
  },
  {
    id: "653",
    opus: "L",
    number: "15",
    title: "Some Children See Him",
    date: "Mid 70’s",
    scoring: "Alto part for duet and cello obbligato",
  },
  {
    id: "654",
    opus: "L",
    number: "16",
    title: "Intro to Close Encounter Opening",
    date: "December 1978",
    scoring: "Orchestra",
  },
  {
    id: "655",
    opus: "L",
    number: "17",
    title:
      "Male Chorus Orchestra inserts Misc: Bali Ha'i, Anchors, Clambake Pensance)",
    date: "December 1979",
    scoring: "Orchestra",
  },
  {
    id: "656",
    opus: "L",
    number: "18",
    title: "Orchestral Bridges to Annie",
    date: "January 1980",
    scoring: "Orchestra",
  },
  {
    id: "657",
    opus: "L",
    number: "19",
    title: "Chorus inserts to Orchestration of White Christmas",
    date: "October 27 1980",
    scoring: "Chorus",
  },
  {
    id: "658",
    opus: "O",
    number: "32",
    title: "Winter Wonderland",
    date: "November 14 1980",
    scoring: "Orchestra",
  },
  {
    id: "659",
    opus: "O",
    number: "33",
    title: "God Bless America",
    date: "12 Jan",
    scoring: "Choir/Orchestra",
  },
  {
    id: "660",
    opus: "59",
    number: "",
    title: "Tuba Quartet  (in 3 movements)",
    date: "18 February 1981",
    scoring: "Euphonium, 3 Tubas (E-flat, CC and BB-flat)",
  },
  {
    id: "661",
    opus: "O",
    number: "34",
    title: "Orchestra Demonstration No. 2 (1980-81)",
    date: "December 1980",
    scoring: "Orchestra",
  },
  {
    id: "662",
    opus: "60",
    number: "",
    title:
      "Far Above Rubies (A Cantata for Women’s Voices, soloists and Chamber orchestra)",
    date: "Early 1981 ------",
    scoring: "Chamber Orchestra and Soloists",
  },
  {
    id: "663",
    opus: "61",
    number: "",
    title: "Fantasy For Strings (in 4 movements)",
    date: "April 6 - May 28, 1981",
    scoring: "String Orchestra",
  },
  {
    id: "664",
    opus: "62",
    number: "",
    title: "Stonehenge (for Brass Choir and Percussion)",
    date: "June 2 - 26, 1981",
    scoring: "Brass Choir and Percussion",
  },
  {
    id: "665",
    opus: "63",
    number: "",
    title:
      "Spring Fancies (for Solo Celesta, Strings and Percussion)\n1- Kathie's Song\n2- Misty\n3- Frolic\n4-Nocturne\n5 -Zephyr\n6-Reprise",
    date: "June 27, 1982 - January 11, 1983",
    scoring: "Celesta, Strings and Percussion",
  },
  {
    id: "666",
    opus: "64",
    number: "",
    title: "Fanfaronnade",
    date: "July 17, 1981",
    scoring: "Orchestra",
  },
  {
    id: "667",
    opus: "O",
    number: "35",
    title: "Orchestral Demonstration No. 3",
    date: "December 1, 1981",
    scoring: "Orchestra",
  },
  {
    id: "668",
    opus: "L",
    number: "20",
    title: "Temptation of St. Anthony from Mathis der Mahler by Hindemith",
    date: "March 27, 1982",
    scoring: "Orchestra",
  },
  {
    id: "669",
    opus: "57",
    number: "2",
    title: "Timpani Audition Exercise",
    date: "Written 1979-80",
    scoring: "Timpani",
  },
  {
    id: "670",
    opus: "L",
    number: "21",
    title: "Ending to “Deck the Halls”",
    date: "November 29, 1982",
    scoring: "Orchestra",
  },
  {
    id: "671",
    opus: "57",
    number: "3",
    title: "Fanfares to Kinderkonzert in a Castle\nFanfare #1\nFanfare #2",
    date: "November 29, 1982",
    scoring: "Brass Choir/Percussion",
  },
  {
    id: "672",
    opus: "65",
    number: "",
    title: "Timpanogos",
    date: "1983",
    scoring: "Organ",
  },
  {
    id: "673",
    opus: "65",
    number: "",
    title: "Timpanogos  (Two Additional Movements)",
    date: "May 1984",
    scoring: "Organ",
  },
  {
    id: "674",
    opus: "O",
    number: "36",
    title: "America the Beautiful",
    date: "1983",
    scoring: "Orchestra",
  },
  {
    id: "675",
    opus: "O",
    number: "37",
    title: "Amazing Grace",
    date: "May 1983",
    scoring: "Orchestra",
  },
  {
    id: "676",
    opus: "C",
    number: "56",
    title: "I Know That My Redeemer Lives",
    date: "July - August 5, 1983",
    scoring: "Chorus (SAB) and Keyboard",
  },
  {
    id: "677",
    opus: "O",
    number: "38",
    title: "Happy Birthday",
    date: "November 7-9, 1983",
    scoring: "Orchestra",
  },
  {
    id: "678",
    opus: "L",
    number: "22",
    title: "Music Man (3 excerpts)",
    date: "January 9, 1984",
    scoring: "Orchestra",
  },
  {
    id: "679",
    opus: "E",
    number: "28",
    title: "Believe Me If All Those Endearing Young Charms",
    date: "January 9, 1984",
    scoring: "Voice and Strings",
  },
  {
    id: "680",
    opus: "66",
    number: "2",
    title: "Psalm (For Low Brass Quintet)",
    date: "May 1984",
    scoring: "Brass  (Low Brass Quintet)",
  },
  {
    id: "681",
    opus: "A",
    number: "7",
    title: "America the Beautiful",
    date: "June 1984",
    scoring: "Solo Piano",
  },
  {
    id: "682",
    opus: "A",
    number: "8",
    title: "We Gather Together",
    date: "July 2, 1984",
    scoring: "Solo Piano",
  },
  {
    id: "683",
    opus: "A",
    number: "9",
    title: "I Wonder When He Comes Again",
    date: "July 10, 1984",
    scoring: "Solo Piano",
  },
  {
    id: "684",
    opus: "45",
    number: "4A",
    title: "Baptism",
    date: "July 11, 1984",
    scoring: "Piano",
  },
  {
    id: "685",
    opus: "B",
    number: "38",
    title: "Rise Up and Walk",
    date: "August 4-10, 1984",
    scoring: "Voice and Piano",
  },
  {
    id: "686",
    opus: "C",
    number: "57",
    title: "All My Life Long",
    date: "December 27-29, 1984",
    scoring: "Mixed Chorus (SATB) accompanied",
  },
  {
    id: "687",
    opus: "67",
    number: "1",
    title: "The Promise of Elijah",
    date: "December 1984",
    scoring: "Mixed Chorus (SATB) accompanied",
  },
  {
    id: "688",
    opus: "L",
    number: "23",
    title: "Yankee Doodle",
    date: "January 10, 1985",
    scoring: "Orchestra",
  },
  {
    id: "689",
    opus: "O",
    number: "39",
    title: "America the Beautiful",
    date: "January 12, 1985",
    scoring: "Chorus, Soloists, Orchestra",
  },
  {
    id: "690",
    opus: "A",
    number: "10",
    title: "Valley Home",
    date: "February 1985",
    scoring: "Music Box",
  },
  {
    id: "691",
    opus: "A",
    number: "11",
    title: "Israel, Israel, God is Calling",
    date: "March 29, 1985",
    scoring: "Piano",
  },
  {
    id: "692",
    opus: "O",
    number: "40",
    title: "Keep The Home Fires Burning",
    date: "April 9, 1985",
    scoring: "Orchestra",
  },
  {
    id: "693",
    opus: "A",
    number: "12",
    title: "High On The Mountain Top",
    date: "April 20, 1985",
    scoring: "Piano",
  },
  {
    id: "694",
    opus: "67",
    number: "2",
    title: "O Festive Day",
    date: "April 30, 1985",
    scoring: "Piano",
  },
  {
    id: "695",
    opus: "A",
    number: "13",
    title: "Nearer My God To Thee",
    date: "May 4, 1985",
    scoring: "Piano",
  },
  {
    id: "696",
    opus: "A",
    number: "14",
    title: "Come We That Love the Lord",
    date: "May 13, 1985",
    scoring: "Piano",
  },
  {
    id: "697",
    opus: "F",
    number: "29",
    title: "O My Father",
    date: "May 13, 1985",
    scoring: "Hymn",
  },
  {
    id: "698",
    opus: "B",
    number: "39",
    title: "You and I (Words and Music by Clyde Sandgren)",
    date: "May 17, 1985",
    scoring: "Voice and Piano",
  },
  {
    id: "699",
    opus: "67",
    number: "3",
    title: "Seek Ye First The Kingdom of God",
    date: "May 29, 1985",
    scoring: "Mixed Chorus (SATB) accompanied",
  },
  {
    id: "700",
    opus: "67",
    number: "4",
    title: "The Promise of the Lord",
    date: "May 31, 1985",
    scoring: "Mixed Chorus (SATB) accompanied",
  },
  {
    id: "701",
    opus: "A",
    number: "15",
    title: "Abide With Me",
    date: "June 6, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "702",
    opus: "A",
    number: "16",
    title: "Redeemer of Israel",
    date: "June 7, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "703",
    opus: "A",
    number: "17",
    title: "Now The Day Is Over",
    date: "June 17, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "704",
    opus: "A",
    number: "18",
    title: "How Great The Wisdom",
    date: "June 17, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "705",
    opus: "58",
    number: "3",
    title: "Prologue",
    date: "June 22, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "706",
    opus: "33",
    number: "11a",
    title: "Down By The River’s Verdant Side",
    date: "June 22, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "707",
    opus: "47",
    number: "3a",
    title: "He Is Not Here",
    date: "June 25, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "708",
    opus: "52",
    number: "4a",
    title: "Spring Green",
    date: "June 26, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "709",
    opus: "29",
    number: "6a",
    title: "The Song of Mary",
    date: "June 27, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "710",
    opus: "50",
    number: "4",
    title: "Ring O Bell Of Freedom",
    date: "June 28, 1985",
    scoring: "Piano Arrangement",
  },
  {
    id: "711",
    opus: "68",
    number: "",
    title: "Cityscape Salute",
    date: "July 3 - 12, 1985",
    scoring: "Chamber Orchestra",
  },
  {
    id: "712",
    opus: "L",
    number: "24",
    title: "We Are The World",
    date: "June 8, 1985",
    scoring: "Strings",
  },
  {
    id: "713",
    opus: "50",
    number: "5",
    title: "Ring O Bell Of Freedom",
    date: "August 1985",
    scoring: "Mixed Chorus (SSAATTBB) accompanied",
  },
  {
    id: "714",
    opus: "69",
    number: "",
    title: "Cantilena Romanza",
    date: "August 1985",
    scoring: "Oboe",
  },
  {
    id: "715",
    opus: "E",
    number: "29",
    title: "High On The Mountain Top",
    date: "September 7, 1985",
    scoring: "Horn Octet",
  },
  {
    id: "716",
    opus: "29",
    number: "6c",
    title: "Chorales from Cumorah",
    date: "September 7, 1985",
    scoring: "Mixed Chorus (SSAATTBB) accompanied",
  },
  {
    id: "717",
    opus: "70",
    number: "",
    title: "Celebration",
    date: "September 19, 1985",
    scoring: "Brassworks",
  },
  {
    id: "718",
    opus: "L",
    number: "25",
    title: "The Teddy Bear Picnic",
    date: "October 16, 1985",
    scoring: "String Parts to a Band Arrangement",
  },
  {
    id: "719",
    opus: "L",
    number: "26",
    title: "Seikilos Song",
    date: "October 26, 1985",
    scoring: "Woodwind unison and string unison",
  },
  {
    id: "720",
    opus: "L",
    number: "27",
    title: "I Dream of Jeanie",
    date: "October 1985",
    scoring: "Orchestra",
  },
  {
    id: "721",
    opus: "L",
    number: "28",
    title: "Muppet Medley",
    date: "1980?",
    scoring: "Orchestra",
  },
  {
    id: "722",
    opus: "O",
    number: "41",
    title: "The World Is A Rainbow",
    date: "January 4-7, 1986",
    scoring: "Orchestra",
  },
  {
    id: "723",
    opus: "O",
    number: "42",
    title: "Pandango Sa Haw",
    date: "January 8, 1986",
    scoring: "Orchestra",
  },
  {
    id: "724",
    opus: "O",
    number: "43",
    title: "I Am But A Small Voice",
    date: "January 1986",
    scoring: "Orchestra",
  },
  {
    id: "725",
    opus: "O",
    number: "44",
    title: "Tinikling",
    date: "January 1986",
    scoring: "Orchestra",
  },
  {
    id: "726",
    opus: "71",
    number: "",
    title: "Ballade For Viola and Piano",
    date: "June - July 1986",
    scoring: "Viola and Piano",
  },
  {
    id: "727",
    opus: "72",
    number: "",
    title: "Rock River Fanfare",
    date: "June 1986",
    scoring: "Brass Choir and Percussion",
  },
  {
    id: "728",
    opus: "73",
    number: "",
    title: "Lake Songs",
    date: "June - August 1986",
    scoring: "Orchestra",
  },
  {
    id: "729",
    opus: "67",
    number: "5",
    title: "Miniature Duo for Marimba and Harp",
    date: "December 19, 1986",
    scoring: "Marimba and Harp",
  },
  {
    id: "730",
    opus: "A",
    number: "19",
    title: "Our Savior’s Love",
    date: "January 17, 1987",
    scoring: "Piano",
  },
  {
    id: "731",
    opus: "A",
    number: "20",
    title: "Where Can I Turn For Peace",
    date: "January 17, 1987",
    scoring: "Piano",
  },
  {
    id: "732",
    opus: "67",
    number: "6",
    title: "On A Golden Springtime",
    date: "February 14, 1987",
    scoring: "Song",
  },
  {
    id: "733",
    opus: "O",
    number: "45",
    title: "Oh, Beloit",
    date: "April 4, 1987",
    scoring: "Orchestra",
  },
  {
    id: "734",
    opus: "73",
    number: "5",
    title: "Bright Beloit",
    date: "April 4, 1987",
    scoring: "School Song",
  },
  {
    id: "735",
    opus: "C",
    number: "58",
    title: "High On The Mountain Top",
    date: "April 12, 1987",
    scoring: "Mixed Chorus (SAB) accompanied",
  },
  {
    id: "736",
    opus: "E",
    number: "30",
    title: "Oh, Beloit",
    date: "May 1987",
    scoring: "Brass Quintet / Piano / Voices",
  },
  {
    id: "737",
    opus: "E",
    number: "31",
    title: "Bright Beloit",
    date: "May 1987",
    scoring: "Brass Quintet / Piano / Voices",
  },
  {
    id: "738",
    opus: "74",
    number: "",
    title:
      "Basso Ostentaccio No. 1) Pizzicato Perpetuoso 2) Lamentoso 3) Gioioso",
    date: "July 1987",
    scoring: "Bass / Piano",
  },
  {
    id: "739",
    opus: "75",
    number: "",
    title: "Music for the New Hill Cumorah Pageant (Symphony No. 6)",
    date: "August 1, 1987 - May 9, 1988",
    scoring: "Orchestra / Chorus",
  },
  {
    id: "740",
    opus: "60",
    number: "1",
    title: "Make a Joyful Noise",
    date: "August 8, 1987",
    scoring: "Voice / Piano or Harp",
  },
  {
    id: "741",
    opus: "C",
    number: "59",
    title: "Bright Beloit",
    date: "August 1987",
    scoring: "Chorus (A capella)",
  },
  {
    id: "742",
    opus: "O",
    number: "46",
    title: "The Cage (The Last Opus)",
    date: "September 1987",
    scoring: "Strings",
  },
  {
    id: "743",
    opus: "O",
    number: "47",
    title: "Land Sighting",
    date: "June 21-25, 1988",
    scoring: "Male Chorus and Orchestra",
  },
  {
    id: "744",
    opus: "75",
    number: "14",
    title: "The Song of Cumorah",
    date: "July 25, 1988",
    scoring: "Chorus (Unison) and Piano",
  },
  {
    id: "745",
    opus: "76",
    number: "",
    title: "Of Work Well Done",
    date: "August 1988",
    scoring:
      "a) Chorus (Piano or Organ) b) Chorus, Children's Chorus, hand-bell choir, piano, organ, chamber orchestra",
  },
  {
    id: "746",
    opus: "77",
    number: "",
    title: "Honor Forever, Weber State College Centennial Overture",
    date: "September 1988",
    scoring: "Orchestra",
  },
  {
    id: "747",
    opus: "L",
    number: "29",
    title: "Ending to Jingle Bells",
    date: "January 24, 1989",
    scoring: "Orchestra",
  },
  {
    id: "748",
    opus: "73",
    number: "5d",
    title: "Bright Beloit",
    date: "May 5, 1989",
    scoring: "String Quartet",
  },
  {
    id: "749",
    opus: "75",
    number: "14a",
    title: "Song of Cumorah",
    date: "May 28, 1989",
    scoring: "Voice (2 parts) and Piano",
  },
  {
    id: "750",
    opus: "E",
    number: "28c",
    title: "Believe Me If All Those Endearing Young Charms",
    date: "June 2-5, 1989",
    scoring: "Mixed Chorus, Soprano, Strings, & Harp.",
  },
  {
    id: "751",
    opus: "67",
    number: "6a",
    title: "On A Golden Springtime",
    date: "June 26, 1989",
    scoring: "Piano arrangement",
  },
  {
    id: "752",
    opus: "A",
    number: "21",
    title: "Christmas Medley",
    date: "June 27, 1989",
    scoring: "Piano arrangement",
  },
  {
    id: "753",
    opus: "L",
    number: "40",
    title: "A Tribute to Gershwin",
    date: "June 29, 1989",
    scoring: "Orchestra",
  },
  {
    id: "754",
    opus: "81",
    number: "",
    title: "Joseph & Emma",
    date: "August 26, 1989",
    scoring: "Film Score",
  },
  {
    id: "755",
    opus: "60",
    number: "2",
    title: "Secret Garden",
    date: "November 25-27, 1989",
    scoring: "Voice and Piano",
  },
  {
    id: "756",
    opus: "C",
    number: "60",
    title: "A Candle By The Window",
    date: "November 28, 1989",
    scoring: "Mixed Chorus (SATB) and Piano",
  },
  {
    id: "757",
    opus: "67",
    number: "8",
    title: "Mother, We Remember Thee",
    date: "December 11, 1989",
    scoring: "Voice and Piano",
  },
  {
    id: "758",
    opus: "C",
    number: "61",
    title: "Come Unto Christ",
    date: "January 15-22, 1990",
    scoring: "Mixed Chorus (SATB) and Piano",
  },
  {
    id: "759",
    opus: "60",
    number: "2a",
    title: "Reverie For Louise",
    date: "January 22, 1990",
    scoring: "Cello and Piano",
  },
  {
    id: "760",
    opus: "78",
    number: "",
    title: "Make A Joyful Noise!",
    date: "February 2, 1990",
    scoring: "Band (Junior High)",
  },
  {
    id: "761",
    opus: "79",
    number: "",
    title: "Suite for Strings",
    date: "March 17, 1990",
    scoring: "Strings (Junior High)",
  },
  {
    id: "762",
    opus: "C",
    number: "62",
    title: "Begin the Beguine (Cole Porter)",
    date: "April 21, 1990",
    scoring: "Chorus Parts and Orchestra",
  },
  {
    id: "763",
    opus: "C",
    number: "63",
    title: "Where or When (Richard Rodgers)",
    date: "May 26, 1990",
    scoring: "Chorus Parts",
  },
  {
    id: "764",
    opus: "80",
    number: "",
    title: "Pentameron (Piano Concerto No. 2)",
    date: "July 15, 1990 - April 1991",
    scoring: "Concerto (Piano and Orchestra)",
  },
  {
    id: "765",
    opus: "82",
    number: "1",
    title: "La Plenumo (Esperanto International Anthem)",
    date: "November 22-23, 1990",
    scoring: "Hymn/Anthem",
  },
  {
    id: "766",
    opus: "83",
    number: "",
    title: "A New Land Called Home",
    date: "March 5, 1992 - May 12, 1992",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "767",
    opus: "60",
    number: "3",
    title: "Far Above Rubies",
    date: "February 27-28, 1991",
    scoring: "Mixed Chorus (SAB) and Piano",
  },
  {
    id: "768",
    opus: "82",
    number: "2",
    title: "Footprints In the Sand",
    date: "March 1991",
    scoring: "Song",
  },
  {
    id: "769",
    opus: "L",
    number: "30",
    title: "Ending: You’ll Never Walk Alone",
    date: "June 1991",
    scoring: "Song",
  },
  {
    id: "770",
    opus: "C",
    number: "64",
    title: "God Bless America",
    date: "June 1991",
    scoring: "Chorus Arrangement",
  },
  {
    id: "771",
    opus: "O",
    number: "48",
    title: "In the Still of the Night",
    date: "June 1991",
    scoring: "Orchestra",
  },
  {
    id: "772",
    opus: "O",
    number: "49",
    title: "So In Love Am I",
    date: "June 1991",
    scoring: "Orchestra",
  },
  {
    id: "773",
    opus: "67",
    number: "7",
    title: "Chorale Prelude",
    date: "October 9, 1986*",
    scoring: "Mixed Chorus (SSAATTBB) and Organ",
  },
  {
    id: "774",
    opus: "82",
    number: "3",
    title: "Freedom Is In the Air",
    date: "December 23, 1991",
    scoring: "Mixed Chorus (SSAATTBB) and Accompaniment",
  },
  {
    id: "775",
    opus: "O",
    number: "50",
    title: "Ah Sweet Mystery",
    date: "January 9-11, 1992",
    scoring: "Orchestra",
  },
  {
    id: "776",
    opus: "O",
    number: "51",
    title: "Aloha Oe",
    date: "January 11-12, 1992",
    scoring: "Orchestra",
  },
  {
    id: "777",
    opus: "O",
    number: "52",
    title: "Waltzing Matilda",
    date: "January 25, 1992",
    scoring: "Strings and Piano",
  },
  {
    id: "778",
    opus: "84",
    number: "",
    title: "Folk Song Suite",
    date: "February 10-14, 1992",
    scoring: "Flute, Violin, and Cello",
  },
  {
    id: "779",
    opus: "45",
    number: "4-B",
    title: "Baptism",
    date: "March 4, 1992",
    scoring: "Mixed Chorus (SATB) and Piano",
  },
  {
    id: "780",
    opus: "C",
    number: "65",
    title: "You’re A Grand Old Flag",
    date: "June 8-9, 1992",
    scoring: "Chorus Arrangement",
  },
  {
    id: "781",
    opus: "85",
    number: "",
    title: "Live In the Light",
    date: "May 11 - August 17, 1992",
    scoring: "SATB and Piano",
  },
  {
    id: "782",
    opus: "86",
    number: "",
    title: "Coro",
    date: "August 17, 1992",
    scoring: "Euphonium and Piano",
  },
  {
    id: "783",
    opus: "L",
    number: "31",
    title: "Out of My Dreams",
    date: "June 3, 1992",
    scoring: "Orchestration Insert",
  },
  {
    id: "784",
    opus: "71",
    number: "a",
    title: "Ballade",
    date: "September 1992",
    scoring: "Viola and Chamber Orchestra",
  },
  {
    id: "785",
    opus: "87",
    number: "",
    title: "Visions of Eternity",
    date: "October 20, 1992",
    scoring: "Oratorio (Mixed Chorus, Soloists, Orchestra)",
  },
  {
    id: "786",
    opus: "47",
    number: "3b",
    title: "He Is Not Here",
    date: "1992 (out of place)",
    scoring: "Mixed Chorus (SAB)",
  },
  {
    id: "787",
    opus: "L",
    number: "32",
    title: "Til There Was You (From Music Man)",
    date: "1992 (out of place)",
    scoring: "Soprano and Orchestra",
  },
  {
    id: "788",
    opus: "L",
    number: "33",
    title: "Belle of the Ball",
    date: "January 15, 1993",
    scoring: "Orchestra",
  },
  {
    id: "789",
    opus: "L",
    number: "34",
    title: "Waltz at Maxims",
    date: "January 16, 1993",
    scoring: "Orchestra",
  },
  {
    id: "790",
    opus: "O",
    number: "53",
    title: "Stardust",
    date: "June 3-10, 1993",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "791",
    opus: "D",
    number: "37",
    title: "Basin Street Blues",
    date: "June 1993",
    scoring: "Dance Band",
  },
  {
    id: "792",
    opus: "D",
    number: "38",
    title: "Ciribiribin",
    date: "June 1993",
    scoring: "Dance Band",
  },
  {
    id: "793",
    opus: "88",
    number: "",
    title: "Lyric Dances",
    date: "July 1993 - May 7, 1994",
    scoring: "Flute and Piano (Percussion Optional)",
  },
  {
    id: "794",
    opus: "89",
    number: "",
    title: "Jubilation Fanfare",
    date: "July 1993 - August 13, 1994",
    scoring: "Brass Choir and Percussion",
  },
  {
    id: "795",
    opus: "49",
    number: "6a",
    title: "Our Savior’s Love",
    date: "August 13, 1993",
    scoring: "Male Quartet  (TTBB)",
  },
  {
    id: "796",
    opus: "O",
    number: "54",
    title: "They Can’t Take That Away From Me",
    date: "December 29, 1993",
    scoring: "Female Trio",
  },
  {
    id: "797",
    opus: "O",
    number: "55",
    title: "Boogie Woogie Bugle Boy",
    date: "December 30, 1993",
    scoring: "Female Trio",
  },
  {
    id: "798",
    opus: "O",
    number: "56",
    title: "Sincerely",
    date: "December 31, 1993",
    scoring: "Female Trio",
  },
  {
    id: "799",
    opus: "L",
    number: "35",
    title: "Celebratory Percussion Procession",
    date: "February 8, 1994",
    scoring: "Percussion",
  },
  {
    id: "800",
    opus: "F",
    number: "30",
    title: "Stardust",
    date: "June 1994",
    scoring: "Female Trio",
  },
  {
    id: "801",
    opus: "L",
    number: "36",
    title: "I Got Plenty O’ Nuttin’ (Porgy and Bess)",
    date: "January 1992 (out of place)",
    scoring: "Strings and Woodwinds",
  },
  {
    id: "802",
    opus: "L",
    number: "37",
    title: "Gotterdamurung (Excerpt_",
    date: "Early 80s (out of place)",
    scoring: "Orchestra",
  },
  {
    id: "803",
    opus: "L",
    number: "38",
    title: "Kismet",
    date: "1992 (out of place)",
    scoring: "Orchestra",
  },
  {
    id: "804",
    opus: "L",
    number: "39",
    title: "O What a Beautiful Morning (From ‘Oklahoma’)",
    date: "1990 (out of place)",
    scoring: "Orchestra",
  },
  {
    id: "805",
    opus: "B",
    number: "40",
    title: "O How Lovely",
    date: "July 5-8, 1994",
    scoring: "Voice and Piano",
  },
  {
    id: "806",
    opus: "B",
    number: "41",
    title: "O My Father",
    date: "July 12-13, 1994",
    scoring: "Voice and Piano",
  },
  {
    id: "807",
    opus: "B",
    number: "42",
    title: "I Stand All Amazed",
    date: "July 16-19, 1994",
    scoring: "Voice and Piano",
  },
  {
    id: "808",
    opus: "B",
    number: "43",
    title: "Our Savior’s Love",
    date: "July 22, 1994",
    scoring: "Voice and Piano",
  },
  {
    id: "809",
    opus: "90",
    number: "",
    title: "Desert As a Rose",
    date: "July 5, 1995 - February 17, 1996",
    scoring: "Ballet",
  },
  {
    id: "810",
    opus: "L",
    number: "41",
    title: "Interlude (Based on Search, Ponder and Pray)",
    date: "November 14, 1994",
    scoring: "Interlude",
  },
  {
    id: "811",
    opus: "O",
    number: "57",
    title: "Vilia (The Merry Widow) Lehar",
    date: "November 24, 1994",
    scoring: "Orchestra",
  },
  {
    id: "812",
    opus: "O",
    number: "58",
    title: "Falling In Love With Love",
    date: "November 29, 1994",
    scoring: "Orchestra",
  },
  {
    id: "813",
    opus: "G",
    number: "41",
    title: "Mary Is A Grand Old Name",
    date: "December 12, 1994",
    scoring: "Male Chorus and Strings",
  },
  {
    id: "814",
    opus: "O",
    number: "59",
    title: "Diamonds Are a Girl’s Best Friend",
    date: "December 19-26, 1994",
    scoring: "Voice and Orchestra",
  },
  {
    id: "815",
    opus: "L",
    number: "42",
    title: "A Pretty Girl Is Like a Melody",
    date: "January 9, 1995",
    scoring: "Orchestra",
  },
  {
    id: "816",
    opus: "L",
    number: "43",
    title: "Marie (Irving Berlin)",
    date: "January 12, 1995",
    scoring: "Orchestra",
  },
  {
    id: "817",
    opus: "O",
    number: "60",
    title: "Drink To Me Only With Thine Eyes",
    date: "January 30-31, 1995",
    scoring: "Voice, Strings, Harp, Celesta, and Glockenspiel",
  },
  {
    id: "818",
    opus: "O",
    number: "61",
    title: "Elegie from Sonata for Oboe and Piano (Francis Poulenc)",
    date: "February 3, 1995",
    scoring: "Oboe, Strings, Harp and Celesta",
  },
  {
    id: "819",
    opus: "60",
    number: "1c",
    title: "Make a Joyful Noise",
    date: "February 3, 1995",
    scoring: "Strings, Harp, Celesta, Clarinet, and Glockenspiel",
  },
  {
    id: "820",
    opus: "B",
    number: "44",
    title: "Lead Kindly Light",
    date: "March 27, 1995",
    scoring: "Voice and Piano",
  },
  {
    id: "821",
    opus: "60",
    number: "4",
    title: "Kindness Curled In Our Hand",
    date: "May 18-19, 1995 - June 1995",
    scoring: "Voice",
  },
  {
    id: "822",
    opus: "O",
    number: "62",
    title: "Can’t Help Lovin’ Dat Man",
    date: "October 7, 1994",
    scoring: "Orchestra",
  },
  {
    id: "823",
    opus: "82",
    number: "4",
    title: "Christ Child Soliloquy",
    date: "November 14, 1995",
    scoring: "Song",
  },
  {
    id: "824",
    opus: "C",
    number: "66",
    title: "Glory to God On High",
    date: "April 4, 1996",
    scoring: "Descant (Soprano)",
  },
  {
    id: "825",
    opus: "C",
    number: "67",
    title: "Faith of Our Fathers",
    date: "April 5, 1996",
    scoring: "Descant (Soprano)",
  },
  {
    id: "826",
    opus: "O",
    number: "63",
    title: "Sing a Song of Peace",
    date: "April 22-23, 1996",
    scoring: "Orchestra",
  },
  {
    id: "827",
    opus: "O",
    number: "64",
    title: "Picnic of the World",
    date: "April 25-29, 1996",
    scoring: "Orchestra",
  },
  {
    id: "828",
    opus: "L",
    number: "44",
    title: "You’ll Never Walk Alone",
    date: "Late May 1996",
    scoring: "Chorus",
  },
  {
    id: "829",
    opus: "O",
    number: "65",
    title: "Shopiere Town",
    date: "Early June 1996",
    scoring: "Orchestra",
  },
  {
    id: "830",
    opus: "O",
    number: "66",
    title: "The Man I Love",
    date: "Mid June 1996",
    scoring: "Orchestra",
  },
  {
    id: "831",
    opus: "91",
    number: "",
    title: "Taliesin Fanfare",
    date: "July 15 - August 1, 1996",
    scoring: "Brass and Percussion",
  },
  {
    id: "832",
    opus: "B",
    number: "45",
    title: "Come Ye Thankful People Come",
    date: "August 8, 1996",
    scoring: "Solo Voice",
  },
  {
    id: "833",
    opus: "L",
    number: "45",
    title: "Old Man River (from Showboat)",
    date: "January 1996",
    scoring: "Orchestra",
  },
  {
    id: "834",
    opus: "L",
    number: "46",
    title: "Only Make Believe (from Showboat)",
    date: "January 1996",
    scoring: "Orchestra",
  },
  {
    id: "835",
    opus: "L",
    number: "47",
    title: "You are Love (from Showboat)",
    date: "January 1996",
    scoring: "Orchestra",
  },
  {
    id: "836",
    opus: "92",
    number: "",
    title: "Songs of Celebration",
    date: "November 20, 1996",
    scoring: "Orchestra",
  },
  {
    id: "837",
    opus: "93",
    number: "",
    title: "Far Above Rubies",
    date: "December 26, 1996 - January 20, 1997",
    scoring: "Song Cycle (Low Voice and Piano)",
  },
  {
    id: "838",
    opus: "94",
    number: "",
    title: "Legend of the White Buffalo - Gift of Peace",
    date: "February 2, 1997 - 3 Weeks Later",
    scoring: "Orchestra",
  },
  {
    id: "839",
    opus: "95",
    number: "",
    title: "Blackhawk!",
    date: "May 12 - August 22, 1997",
    scoring: "Concert Band",
  },
  {
    id: "840",
    opus: "O",
    number: "67",
    title: "Just A Wearyin’ For You",
    date: "June 3, 1997",
    scoring: "Orchestra",
  },
  {
    id: "841",
    opus: "100",
    number: "",
    title: "Joseph, Joseph!",
    date: "March 27, 1997",
    scoring: "Opera",
  },
  {
    id: "842",
    opus: "O",
    number: "68",
    title: "America",
    date: "June 6, 1997",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "843",
    opus: "O",
    number: "69",
    title: "On Wisconsin",
    date: "June 7, 1997",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "844",
    opus: "O",
    number: "70",
    title: "I Love You Truly",
    date: "June 9, 1997",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "845",
    opus: "O",
    number: "71",
    title: "The Perfect Day",
    date: "June 11, 1997",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "846",
    opus: "A",
    number: "22",
    title: "Jingle Bells (A Scherzo for Piano)",
    date: "November 18, 1997",
    scoring: "Piano",
  },
  {
    id: "847",
    opus: "96",
    number: "",
    title: "Symphony No. 7, “A Triumphal Trilogy”",
    date: "Proposed in 1998 for 2003",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "848",
    opus: "97",
    number: "3",
    title: "Shenandoah Caprice",
    date: "January 2 - February 28, 1998",
    scoring: "Violin and Piano",
  },
  {
    id: "849",
    opus: "O",
    number: "72",
    title: "simple gifts",
    date: "April 23, 1998",
    scoring: "Orchestra",
  },
  {
    id: "850",
    opus: "O",
    number: "73",
    title: "Wisconsin Memories",
    date: "June 9-13, 1998",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "851",
    opus: "81",
    number: "3a",
    title: "No. 3 Cantilena",
    date: "June 22-26, 1998",
    scoring: "Violin and Piano",
  },
  {
    id: "852",
    opus: "98",
    number: "",
    title: "Encore Molto!",
    date: "August 15 - October 7, 1998",
    scoring: "French Horn, Strings, Harp, Celeste and four horns at the Coda",
  },
  {
    id: "853",
    opus: "99",
    number: "",
    title: "Brother Dutton, Servant of God!",
    date: "Early October 1998",
    scoring: "Narrator, Soprano, and Orchestra",
  },
  {
    id: "854",
    opus: "O",
    number: "74",
    title: "Our Savior’s Love",
    date: "October 19, 1998",
    scoring: "Violin and Orchestra",
  },
  {
    id: "855",
    opus: "75",
    number: "5a",
    title: "Harvest Song",
    date: "March 1999",
    scoring: "Violin, Horn, Piano and Percussion",
  },
  {
    id: "856",
    opus: "O",
    number: "75",
    title: "Where Love Is",
    date: "January 2000",
    scoring: "Violin and Piano",
  },
  {
    id: "857",
    opus: "O",
    number: "75a",
    title: "Where Love Is",
    date: "January 2000",
    scoring: "Violin and Orchestra",
  },
  {
    id: "858",
    opus: "101",
    number: "",
    title: "Three For Three",
    date: "August 2000",
    scoring: "Strings",
  },
  {
    id: "859",
    opus: "L",
    number: "48",
    title: "Lead Kindly Light",
    date: "October 19-20, 2000",
    scoring: "Revision",
  },
  {
    id: "860",
    opus: "L",
    number: "49",
    title: "Interludes",
    date: "November 15, 2000",
    scoring: "Violin and Organ",
  },
  {
    id: "861",
    opus: "102",
    number: "",
    title: "Jubilee!",
    date: "March - April 2001",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "862",
    opus: "103",
    number: "",
    title: "Meditation on Treble Clef Centenary",
    date: "June 18, 2001",
    scoring: "Strings and  Piano (4-Hand)",
  },
  {
    id: "863",
    opus: "104",
    number: "",
    title: "Pioneers We Too Can Be",
    date: "June 19, 2001",
    scoring: "Song  (Octet and Tenor Solo)",
  },
  {
    id: "864",
    opus: "E",
    number: "32",
    title: "The Old Y Bell",
    date: "August 24, 2001",
    scoring: "Male Quartet and Brass Quintet",
  },
  {
    id: "865",
    opus: "E",
    number: "33",
    title: "Happy Birthday",
    date: "September 8, 2001",
    scoring: "Male Quartet and Brass Quintet",
  },
  {
    id: "866",
    opus: "B",
    number: "46",
    title: "Little Feet of Beauty",
    date: "January 31, 2002",
    scoring: "Song",
  },
  {
    id: "867",
    opus: "G",
    number: "43",
    title: "O Come, O Come, Immanuel",
    date: "November 27, 2002",
    scoring: "Male Chorus",
  },
  {
    id: "868",
    opus: "L",
    number: "49",
    title: "O Little Town of Bethlehem",
    date: "December 8, 2002",
    scoring: "Flute Obbligato",
  },
  {
    id: "869",
    opus: "105",
    number: "",
    title: "For I Am Called By Thy Name",
    date: "January 3-7, 2003",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "870",
    opus: "A",
    number: "23",
    title: "Beautiful Savior",
    date: "January 20, 2003",
    scoring: "Violin and Piano",
  },
  {
    id: "871",
    opus: "106",
    number: "",
    title: "Winter Radiance - Lake Powell",
    date: "February 6, 2003",
    scoring: "Piano",
  },
  {
    id: "872",
    opus: "B",
    number: "47",
    title: "On a Golden Springtime",
    date: "March 17, 2003",
    scoring: "Voice and Piano",
  },
  {
    id: "873",
    opus: "81",
    number: "2a",
    title: "O Ye Mountains High",
    date: "July 5, 2003",
    scoring: "Violin, Strings, and Harp",
  },
  {
    id: "874",
    opus: "F",
    number: "31",
    title: "Sirens",
    date: "1983 - 1985",
    scoring: "Female Chorus and Piano",
  },
  {
    id: "875",
    opus: "G",
    number: "44",
    title: "Help Me Teach With Inspiration",
    date: "Nov 1, 2003",
    scoring: "Male Chorus and Piano",
  },
  {
    id: "876",
    opus: "100",
    number: "14",
    title: "Prelude to “Joseph! Joseph!”",
    date: "February 23 - March 4, 2004",
    scoring: "Brass Septet, Organ, Timpani 4, Percussion and 2 Pianos",
  },
  {
    id: "877",
    opus: "A",
    number: "24",
    title: "Baptism",
    date: "Out of Place",
    scoring: "Piano",
  },
  {
    id: "878",
    opus: "A",
    number: "25",
    title: "On a Golden Springtime",
    date: "Out of Place",
    scoring: "Piano",
  },
  {
    id: "879",
    opus: "107",
    number: "",
    title: "O Rose Bouquet (Song Cycle)",
    date: "August 24, 2004",
    scoring: "Voice (Low) and Piano",
  },
  {
    id: "880",
    opus: "B",
    number: "49",
    title: "I Know That My Redeemer Lives",
    date: "August 25, 2004",
    scoring: "High Voice and Piano",
  },
  {
    id: "881",
    opus: "100",
    number: "15",
    title: "Nauvoo Band March No. 2",
    date: "April 2004",
    scoring: "Brass Choir and Percussion",
  },
  {
    id: "882",
    opus: "108",
    number: "",
    title: "Brief Moments",
    date: "January 1 - February 1, 2005",
    scoring: "Piano (2), Violins (2), Oboe and Percussion",
  },
  {
    id: "883",
    opus: "O",
    number: "76",
    title: "Our Savior’s Love",
    date: "April 5-18, 2005",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "884",
    opus: "87",
    number: "20",
    title: "Our Savior’s Love",
    date: "April 2005",
    scoring: "Chorus and Orchestra",
  },
  {
    id: "885",
    opus: "E",
    number: "34",
    title: "Dallas Fanfare",
    date: "Spring 2005",
    scoring: "Brass Choir, Timpani and Percussion",
  },
  {
    id: "886",
    opus: "B",
    number: "51",
    title: "Dear to the Heart",
    date: "March 11, 2011",
    scoring: "Duet --Soprano and Tenor",
  },
  {
    id: "887",
    opus: "62a",
    number: "",
    title: "Stonehenge",
    date: "October 7, 2005 - January 20, 2006",
    scoring: "Brass Choir",
  },
  {
    id: "888",
    opus: "57",
    number: "5a",
    title: "Ring Out Wild Bells!",
    date: "December 27, 2005",
    scoring: "Viola and Violin",
  },
  {
    id: "889",
    opus: "109",
    number: "2",
    title: "Martha Graham’s Pianist",
    date: "November 5 - February 6, 2006",
    scoring: "Voice (High) and Piano",
  },
  {
    id: "890",
    opus: "F",
    number: "32",
    title: "O My Luve’s Like a Red Red Rose",
    date: "April 11-18, 2006",
    scoring: "Female Voices and Piano",
  },
  {
    id: "891",
    opus: "110",
    number: "",
    title: "Fanfare",
    date: "April 20, 2006",
    scoring: "Brass Choir and Percussion",
  },
  {
    id: "892",
    opus: "B",
    number: "50",
    title: "You’ll Never Walk Alone",
    date: "August 15-18, 2006",
    scoring: "Voice and Piano",
  },
  {
    id: "893",
    opus: "111",
    number: "",
    title: "Piano Sonata",
    date: "January - April 2007",
    scoring: "Piano",
  },
  {
    id: "894",
    opus: "112",
    number: "",
    title: "Franklin",
    date: "May 2007",
    scoring: "Chamber Orchestra",
  },
  {
    id: "895",
    opus: "113",
    number: "",
    title: "Evening Sets on the Yellowstone",
    date: "July 2007",
    scoring: "Male Voice and Piano",
  },
  {
    id: "896",
    opus: "114",
    number: "",
    title:
      "A Tonal Trio  114 No. 1 A Love Song  114 No. 2 Harvest Dance  114 No. 3  A Fast Race",
    date: "March 9-19 2007 and additional 7/25/2007",
    scoring: "Trio: Flute-Cello-Piano (optional percussion)",
  },
  {
    id: "897",
    opus: "C",
    number: "68",
    title: "Let the Mountains Shout for Joy",
    date: "November 2, 2007 (out of place)",
    scoring: "Mixed Chorus and Accompaniment",
  },
  {
    id: "898",
    opus: "G",
    number: "45",
    title: "If You Could Hie to Kolob",
    date: "January 5-8, 2007",
    scoring: "Male Chorus (TTBB), and Piano",
  },
  {
    id: "899",
    opus: "115",
    number: "",
    title: "Brighton Processional",
    date: "March 8, 2008",
    scoring: "Orchestra",
  },
  {
    id: "900",
    opus: "116",
    number: "",
    title: "Improvised Unison Melody",
    date: "Summer 2008",
    scoring: "Piano",
  },
  {
    id: "901",
    opus: "117",
    number: "",
    title:
      "The Healing Power of Jesus Christ  \nNo. 1  O, My Father\nNo. 2  Master Say On  \nNo. 3  One Thing is Needful  \nNo. 4  The Gathering",
    date: "Summer 2008",
    scoring: "Song Cycle: 4 Voices and Piano",
  },
  {
    id: "902",
    opus: "118",
    number: "",
    title: "Camelot Romance",
    date: "April 1-15, 2009",
    scoring: "Cello and Piano",
  },
  {
    id: "903",
    opus: "E",
    number: "35",
    title: "Baptism",
    date: "April 6 - May 13, 2009",
    scoring: "Song and Instrumental Variations",
  },
  {
    id: "904",
    opus: "A",
    number: "26",
    title: "In Our Lovely Desparate",
    date: "April 7, 2009",
    scoring: "Piano",
  },
  {
    id: "905",
    opus: "O",
    number: "77 and 77a",
    title: "Oh It Is Wonderful",
    date: "August 7, 2009",
    scoring: "Mixed Chorus and Contralto",
  },
  {
    id: "906",
    opus: "119",
    number: "",
    title:
      "Song Cycle No. 5  The Atonement\nNo. 1:  (Please) Take Away thie [Bitter] Cup Text Mark 14:36\nNo. 2:  List to HIm, Who is the Advocate with the Father D&C 45:3-5\nNo. 3:  For God So Loved the World Text John 3:16",
    date: "November - December 2009",
    scoring: "Song Cycle",
  },
  {
    id: "907",
    opus: "120",
    number: "",
    title: "Fanfare for Lake Country",
    date: "May 2010",
    scoring: "Orchestra",
  },
  {
    id: "908",
    opus: "121",
    number: "",
    title: "Trilogy",
    date: "August - September 2010",
    scoring: "Cello Trio and Piano",
  },
  {
    id: "909",
    opus: "A",
    number: "26",
    title: "Lead Kindly LIght",
    date: "July 24, 2010",
    scoring: "Piano Solo",
  },
  {
    id: "910",
    opus: "100",
    number: "4a",
    title: "Joseph’s “Amusing” Tales",
    date: "October 2010 - February 2011",
    scoring: "Scene from Opera Piano Vocal Score Jospeh and Family (Piano)",
  },
  {
    id: "911",
    opus: "122",
    number: "1",
    title: "Music for Ann Madsen’s Film: Isaiah Theme",
    date: "October 2010",
    scoring: "Film Score",
  },
  {
    id: "912",
    opus: "122",
    number: "2",
    title: "Music for Ann Madsen’s Film: Christ Theme",
    date: "December 2010",
    scoring: "Film Score",
  },
  {
    id: "913",
    opus: "?",
    number: "",
    title: "Brass Intro",
    date: "December 11, 2010",
    scoring: "Brass Sextet",
  },
  {
    id: "914",
    opus: "B",
    number: "51",
    title: "Dear to the heart of the Shepherd",
    date: "11 March 2011",
    scoring: "Soprano, tenor and piano",
  },
  {
    id: "915",
    opus: "124",
    number: "",
    title: "Fanfare for a Joyuous Day\nNew 4 Bar Ending",
    date: "December 21, 2010 - January 5, 2011",
    scoring: "Trumpet (in C) and Piano",
  },
  {
    id: "916",
    opus: "123",
    number: "",
    title: "Trumpet Prelude to Rise and Shout",
    date: "July 2011",
    scoring: "Trumpets (8)",
  },
  {
    id: "917",
    opus: "L",
    number: "50",
    title: "Obligatto to Joy to the World",
    date: "Dec 2011",
    scoring: "Voice",
  },
  {
    id: "918",
    opus: "B",
    number: "48",
    title: "Angel Mother",
    date: "2003",
    scoring: "Voice and Piano",
  },
  {
    id: "919",
    opus: "125",
    number: "",
    title: "From My Heart to Yours",
    date: "Jan-Apr 2012",
    scoring: "Contralto, Boy Soprano, Piano",
  },
  {
    id: "920",
    opus: "126",
    number: "",
    title: "God's Love is Everywhere",
    date: "July-August 2012",
    scoring: "SIx Part Voices and Piano",
  },
  {
    id: "921",
    opus: "127",
    number: "",
    title: "Etutde for Cello and Piano",
    date: "September/October 2012",
    scoring: "Cello and Piano",
  },
  {
    id: "922",
    opus: "128",
    number: "",
    title: "Horn Concerto  (3 Movements)",
    date: "March-June 2013",
    scoring: "Horn and Orchestra",
  },
  {
    id: "923",
    opus: "129",
    number: "",
    title: "Violin Concerto",
    date: "2014",
    scoring: "Violin and Orchestra",
  },
  {
    id: "924",
    opus: "130",
    number: "",
    title: "SAVED",
    date: "",
    scoring: "",
  },
  {
    id: "925",
    opus: "F",
    number: "33",
    title: "America the Beautiful",
    date: "February 2014",
    scoring: "Femaile Children's Chorus and Piano",
  },
  {
    id: "926",
    opus: "131",
    number: "",
    title: "Remembrances (3 Movements)",
    date: "February 2014",
    scoring: "Viola and Piano",
  },
  {
    id: "927",
    opus: "E",
    number: "36",
    title: "Praise to the Man",
    date: "2014",
    scoring: "Cello (2) and Piano",
  },
  {
    id: "928",
    opus: "132",
    number: "",
    title: "A Lyric Song:  Joy Cometh on the Morn",
    date: "2015",
    scoring: "Song for Tenor or Soprano",
  },
  {
    id: "929",
    opus: "133",
    number: "",
    title: "Denton Prelude",
    date: "2015",
    scoring: "Piano Solo",
  },
  {
    id: "930",
    opus: "?",
    number: "",
    title: "Valley Home (from Promised Valley)",
    date: "August 11, 1952",
    scoring: "Orchestra",
  },
  {
    id: "931",
    opus: "G",
    number: "20",
    title: "Rework of Dream Girl of Delta Phi",
    date: "",
    scoring: "Male Chorus",
  },
  {
    id: "932",
    opus: "E",
    number: "20b",
    title: "Trumpet Music for the Cumorah Pageant - High on a Mountain Top",
    date: "",
    scoring: "Trumpet Quartet",
  },
  {
    id: "933",
    opus: "L",
    number: "32",
    title: "Til There Was You (From Music Man)",
    date: "Summer 1992 (out of place)",
    scoring: "Orchestra",
  },
];
