import "./App.css";
import { data } from "./data";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo } from "react";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

function App() {
  const columns = useMemo(
    () => [
      {
        accessorKey: "opus", //simple recommended way to define a column
        header: "Opus",
      },
      {
        accessorKey: "number", //simple recommended way to define a column
        header: "Number",
      },
      {
        accessorKey: "title", //simple recommended way to define a column
        header: "Title",
      },
      {
        accessorKey: "scoring", //simple recommended way to define a column
        header: "Scoring",
      },
      {
        accessorKey: "date", //simple recommended way to define a column
        header: "Date",
      },
    ],
    []
  );
  const table = useMaterialReactTable({
    data,
    columns,
  });

  return <MaterialReactTable table={table} />;
}

export default App;
